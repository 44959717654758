import { useEffect, useMemo, useRef, useState } from "react"
import { Link } from "react-router-dom"
import CalendarHeader from "./CalendarHeader"
import { DateTime, Interval } from "luxon"
import WidgetStates from "../SeasonalWidgets/WidgetStates"
import {
    CalendarTimelineItem,
    ICalendarTimelineOutput,
    ICalendarTimelineRow,
} from "../../../types/widget"
import { ITableWidgetProps } from "./TableWidget"
import PaginationControls from "../../Table/PaginationControls"
import {
    getCalendarAlertInfo,
    getGranularityArray,
} from "../../../utils/calendar"
import usePaginationControls from "../../../hooks/usePaginationControls"
import { DefaultParamType, TFnType } from "@tolgee/react"
import {
    getTimeWindowByGranularity,
    TimeResolutionValues,
} from "../../../../views/Seasonal/Alerts/utils"

const alertCategoryFillColor: Record<string, string> = {
    warning: "#BF1728",
    watch: "#CC9300",
}

function GenericCalendarWidget(props: {
    readonly granularity: string
    readonly alertsCalendarData: ICalendarTimelineRow[]
    readonly toolTip: any
    readonly widgetProps: ITableWidgetProps
    translateFn: TFnType<DefaultParamType>
}) {
    const {
        granularity,
        alertsCalendarData,
        toolTip,
        widgetProps,
        translateFn,
    } = props
    const paginationControls = usePaginationControls(alertsCalendarData)

    const shownData = useMemo(() => {
        if (!alertsCalendarData) return
        if (!widgetProps.isPaginated) {
            return alertsCalendarData
        }
        return alertsCalendarData.slice(
            paginationControls.startingIndex,
            paginationControls.endingIndex
        )
    }, [
        paginationControls.startingIndex,
        paginationControls.endingIndex,
        widgetProps.isPaginated,
    ])

    const [width, setWidth] = useState(0)
    const calendarRef = useRef(null)

    // date interval of the granularity
    const [startDate, endDate] = getTimeWindowByGranularity(
        granularity as TimeResolutionValues
    )
    const betweenDates = Interval.fromDateTimes(startDate, endDate)

    // to calculate each column's width
    // const increment = 1
    let steps = 0
    let granularityWidth = 0

    if (["daily", "monthly"].includes(granularity)) {
        steps = betweenDates.length("days") + 1
        granularityWidth = width / steps
    } else if (granularity === "weekly") {
        steps = betweenDates.length("weeks") + 1
        granularityWidth = width / 32
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(
                calendarRef.current ? calendarRef.current["offsetWidth"] : 0
            )
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        window.addEventListener("force-resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("force-resize", handleResize)
        }
    }, [])

    const calendarData = useMemo(() => {
        if (!shownData) return []
        const calendarData: ICalendarTimelineOutput[] = []
        shownData.forEach((loc_row: ICalendarTimelineRow) => {
            let overlap = true
            const calendarRow: CalendarTimelineItem[][] = [[loc_row.items[0]]]
            loc_row.items.slice(1, loc_row.items.length).forEach((item) => {
                for (const alert_row of calendarRow) {
                    if (
                        item.startDate > alert_row[alert_row.length - 1].endDate
                    ) {
                        alert_row.push(item)
                        overlap = false
                        break
                    } else {
                        overlap = true
                    }
                }
                if (overlap) {
                    calendarRow.push([item])
                }
            })
            calendarData.push({
                text: loc_row.text,
                items: calendarRow,
                count: loc_row.items.length,
            })
        })
        return calendarData
    }, [shownData])

    const getEachDateSpaceLength = (granularityArray: DateTime[]) => {
        const eachDateSpaceLength: number[] = []
        granularityArray.forEach((date: DateTime, i) => {
            if (i != granularityArray.length - 1) {
                const dateInterval = Interval.fromDateTimes(
                    granularityArray[i],
                    granularityArray[i + 1]
                ).length("days")
                eachDateSpaceLength.push(dateInterval)
            } else {
                const lastDateSpace =
                    Interval.fromDateTimes(granularityArray[i], endDate).length(
                        "days"
                    ) + 1
                eachDateSpaceLength.push(lastDateSpace)
            }
        })
        return { eachDateSpaceLength }
    }

    const { granularityArray, shownDates } = getGranularityArray(
        startDate,
        granularity,
        steps,
        steps,
        //
        1
    )

    const { eachDateSpaceLength } = getEachDateSpaceLength(granularityArray)

    return (
        <div className="w-full h-full overflow-visible border border-1 border-gray-14 rounded-lg relative z-1 p-2 bg-white">
            <h1
                className={`w-full flex h-[48px] p-0 pl-2 items-center top-0 left-0 z-10 label-lg ${
                    widgetProps.loading ? "text-gray-30" : "text-gray-90"
                }`}>
                {widgetProps.title}
            </h1>
            <div className="flex justify-end border-b border-gray-14 items-center w-full">
                <div
                    ref={calendarRef}
                    className="flex-wrap w-[90%]">
                    <div className="flex w-full">
                        <CalendarHeader
                            shownDates={shownDates}
                            responsiveWidth={granularityWidth}
                            eachSpaceLength={eachDateSpaceLength}
                        />
                    </div>
                </div>
            </div>

            <WidgetStates
                data={widgetProps.data}
                loading={widgetProps.loading}
                reload={widgetProps.reload}
                error={widgetProps.error}
                columns={[]}
                errorMessage={widgetProps.errorMessage}
                reloadMessage={widgetProps.reloadMessage}
                noResultsMessage={widgetProps.noResultsMessage}
                tryAnotherFilterMessage={widgetProps.tryAnotherFilterMessage}
            />

            {calendarData.map((location) => (
                // THIS PART SHOWS EACH ROW
                <div
                    className="flex w-full items-stretch border-b-[0.5px] border-gray-14 h-full bg-gray-3"
                    key={location.text.value}>
                    <div className="w-[10%] flex-wrap pl-2">
                        <Link
                            to={location.text.link ?? ""}
                            className="text-[14px] cursor-pointer underline text-accent w-full">
                            {location.text.value}
                        </Link>
                        <h1 className="body-sm w-full text-gray-60">
                            {location.count +
                                (location.count > 1 ? " alerts" : " alert")}
                        </h1>
                    </div>

                    <div
                        className="flex flex-col w-[90%] -ml-[1px] py-2"
                        style={{
                            background: `repeating-linear-gradient(to right, #F2F3F3, #F2F3F3 1px, white 0px, white ${
                                granularity != "monthly"
                                    ? granularityWidth
                                    : granularityWidth * 7
                            }px`,
                        }}>
                        {location.items.map((alertsRow) => (
                            <div
                                className="flex w-full relative h-10"
                                key={`alert-row-${location.text.value}`}>
                                {alertsRow.map((item) => {
                                    const {
                                        AlertIcon,
                                        alertCategory,
                                        alertShortDescription,
                                        fitsCompleteAlert,
                                        leftSpace,
                                        alertDaysLength,
                                        startsBeforeToday,
                                    } = getCalendarAlertInfo(
                                        item,
                                        startDate,
                                        endDate,
                                        translateFn
                                    )
                                    return (
                                        <div
                                            key={item.text}
                                            className="absolute h-full group"
                                            style={{
                                                width:
                                                    alertDaysLength *
                                                        granularityWidth +
                                                    "px",
                                                left:
                                                    granularityWidth *
                                                        leftSpace +
                                                    "px",
                                            }}>
                                            <span
                                                style={{
                                                    left:
                                                        width -
                                                            leftSpace *
                                                                granularityWidth >
                                                        288
                                                            ? "20%"
                                                            : "",
                                                    right:
                                                        width -
                                                            leftSpace *
                                                                granularityWidth <
                                                        288
                                                            ? "20%"
                                                            : "",
                                                }}
                                                className="absolute z-60 bottom-6 hidden group-hover:block text-[14px] bg-white rounded-md border-[1px] border-gray-14 elevation-2">
                                                {toolTip(item)}
                                            </span>
                                            <div className="flex">
                                                <div className="w-[18px] h-[18px] min-w-[18px] min-h-[18px]">
                                                    <AlertIcon
                                                        className="mr-2"
                                                        fill={
                                                            alertCategoryFillColor[
                                                                alertCategory as string
                                                            ] || "#666D74"
                                                        }
                                                    />
                                                </div>
                                                {alertDaysLength *
                                                    granularityWidth >
                                                30 ? (
                                                    <h1 className="body-sm truncate ml-1 text-gray-60">
                                                        {alertShortDescription}
                                                    </h1>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div
                                                className={`rounded-sm h-[12px] ${
                                                    !fitsCompleteAlert
                                                        ? "rounded-r-none"
                                                        : ""
                                                } ${
                                                    startsBeforeToday
                                                        ? "rounded-l-none"
                                                        : ""
                                                }`}
                                                style={{
                                                    backgroundColor: item.color,
                                                }}></div>
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {widgetProps.isPaginated && (
                <PaginationControls
                    selectedPageSize={paginationControls.per_page}
                    paginationOptions={paginationControls.defaultPageSizeOpts}
                    handlePageSizeChange={(size) => {
                        paginationControls.onPageSizeChange(size)
                    }}
                    handleFirstClick={paginationControls.onFirstClick}
                    handleLastClick={paginationControls.onLastClick}
                    handlePrevClick={() => {
                        paginationControls.onPageChange(false)
                    }}
                    handleNextClick={() => {
                        paginationControls.onPageChange(true)
                    }}
                    backwardDisabled={paginationControls.page === 1}
                    forwardDisabled={
                        paginationControls.page ===
                        paginationControls.totalPages
                    }
                    currentPage={paginationControls.page}
                    totalPages={paginationControls.totalPages}
                    outOfText={"of"}
                />
            )}
        </div>
    )
}

export default GenericCalendarWidget
