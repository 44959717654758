import React, { ReactNode } from "react"

export interface ITextSegment {
    text: string
    isBold?: boolean
}

export interface IWarningCardProps {
    title: string
    description: ITextSegment[]
    icon: ReactNode
}

const CategoryCard: React.FC<IWarningCardProps> = ({
    title,
    description,
    icon,
}) => {
    return (
        <div className="w-[266px] rounded-md border border-gray-14 bg-white p-3 flex flex-col justify-between elevation-2">
            <div className="mt-0.5 flex items-center">
                <div className="w-6 h-6">{icon}</div>
                <div className="ml-2 body-md font-medium text-gray-90">
                    {title}
                </div>
            </div>

            <div className="ml-8 body-md">
                {description.map((segment) => (
                    <span
                        key={title}
                        className={
                            segment.isBold
                                ? "font-bold text-gray-700"
                                : "font-normal text-gray-400"
                        }>
                        {segment.text}
                    </span>
                ))}
            </div>
        </div>
    )
}

export default CategoryCard
