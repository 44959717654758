import { DefaultParamType, TFnType } from "@tolgee/react"
import { NavigateFunction } from "react-router-dom"
import { Button } from "../../../../climateui/components"
import { ClimateTrendsIcon } from "../../../../climateui/icons"
import { RiskIconHandler } from "../../../../climateui/icons/riskIcons"
import { IHazardProfile } from "../../../../types"
import { createGraphQLProxy } from "../../../../utils/networking"

import {
    extractMatchingValues,
    extractVarietyIds,
    getCategoryStyle,
    handleNavigateToDashboard,
} from "../utils"
import AlertDetailCard from "./AlertDetailCard"
import { alertCategoriesEnum } from "./mapUtils"
import { IAlertDetailCardData, IPanelContent } from "./SidePanel"
import { useContext } from "react"
import { useAssets } from "../../../../providers"
import { RiskProfilesContext } from "../../../../providers/RiskProfilesProvider"

function RiskProfileDetailCard(props: {
    readonly riskProfileInfo: IPanelContent
    readonly riskProfilesVarieties: string[]
    readonly alertCategory: alertCategoriesEnum
    readonly enableForecastButton: boolean
    readonly scope?: number
    readonly availableVarietiesPerAlert?: Record<string, string[]>
    t: TFnType<DefaultParamType, string>
    navigate?: NavigateFunction
}) {
    const {
        riskProfileInfo,
        alertCategory,
        enableForecastButton,
        scope,
        t,
        navigate,
        availableVarietiesPerAlert,
    } = props

    const riskProfileHazardProfiles = createGraphQLProxy(
        riskProfileInfo?.riskProfile
    ).hazard_profiles as IHazardProfile[]

    const { varietiesOptions } = useAssets()
    const { riskProfilesObj } = useContext(RiskProfilesContext)

    const riskProfileId = riskProfileInfo.riskProfile?.id
    const varietyIds = extractVarietyIds(
        availableVarietiesPerAlert || {},
        riskProfilesObj,
        riskProfileId
    )
    const matchingValues = extractMatchingValues(varietyIds, varietiesOptions)
    const childAssetValues = matchingValues.map((item: any) => item.value)

    return (
        <div
            className={`h-full w-full flex flex-wrap gap-x-3 ${
                enableForecastButton ? "border-b border-gray-14" : ""
            }`}>
            <div className="flex flex-row gap-x-2">
                <div
                    className={`w-10 h-10 p-2 shrink-0 rounded flex justify-center items-center ${getCategoryStyle(
                        alertCategory
                    )}`}>
                    <RiskIconHandler
                        hazardProfiles={riskProfileHazardProfiles}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-wrap">
                        <p className="body-md text-gray-90">
                            {riskProfileInfo?.riskProfile?.name}
                        </p>
                        <div className={"w-full body-sm text-gray-60 mb-3"}>
                            {childAssetValues.length > 0
                                ? childAssetValues.join(", ")
                                : `${t(
                                      "locationInsights",
                                      "Location insights"
                                  )}`}
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full gap-y-1">
                        {Object.entries(riskProfileInfo?.alertDetailInfo).map(
                            ([key, detail]) => {
                                const cardDetail =
                                    detail as IAlertDetailCardData
                                return (
                                    <AlertDetailCard
                                        Icon={cardDetail.icon}
                                        textLines={cardDetail.textLines}
                                        key={key}
                                    />
                                )
                            }
                        )}
                    </div>
                    {enableForecastButton && (
                        <div className="flex justify-left items-center my-3">
                            <Button
                                icon={<ClimateTrendsIcon fill={"#FFFFFF"} />}
                                type="small"
                                extend={false}
                                disabled={false}
                                label={t("Forecast")}
                                customClasses="bg-[#F06000] text-white w-[250px] border-[#F06000]"
                                onClick={() => {
                                    if (navigate)
                                        handleNavigateToDashboard(
                                            riskProfileInfo,
                                            scope,
                                            navigate
                                        )
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RiskProfileDetailCard
