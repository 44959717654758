const DefauktRiskIcon = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        className="fill-inherit"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.83023 11.9999L12.0009 20.1706L20.1716 11.9999L12.0009 3.82925L3.83023 11.9999ZM1.70891 11.2928C1.31838 11.6833 1.31838 12.3165 1.70891 12.707L11.2938 22.2919C11.6843 22.6825 12.3175 22.6825 12.708 22.2919L22.2929 12.707C22.6834 12.3165 22.6834 11.6833 22.2929 11.2928L12.708 1.70793C12.3175 1.31741 11.6843 1.31741 11.2938 1.70793L1.70891 11.2928Z"
            fill={props.fill}
        />
        <path
            d="M11 6.99993H13V11.9999H11V6.99993Z"
            fill={props.fill}
        />
        <path
            d="M11 13.9999H13V15.9999H11V13.9999Z"
            fill={props.fill}
        />
    </svg>
)

export default DefauktRiskIcon
