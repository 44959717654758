import { gql } from "graphql-request"
import WidgetWrapper from "../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { ShadedRegionsMapWidget } from "../widgets"
import { IYieldOutlookWidgetProps } from "./types"

const queries = {
    adapt: gql`
        query (
            $decade: Int
            $scenario: String
            $region_ids: [String]
            $asset_ids: [String]
        ) {
            yield_outlook_model(
                filter: { region_ids: $region_ids, asset_ids: $asset_ids }
            ) {
                results {
                    region {
                        id
                        name
                        geojson
                        resolution
                    }
                    stats: decadal_stats(
                        filter: { scenario: $scenario, decade: $decade }
                    ) {
                        results {
                            tercile_probabilities
                        }
                    }
                }
            }
        }
    `,
    monitor: gql`
        query (
            $state_ids: [String]
            $asset_ids: [String]
            $init_time: String
            $units: String
        ) {
            yield_outlook_model(
                filter: {
                    region_ids: $state_ids
                    asset_ids: $asset_ids
                    seasonal_status: "active"
                }
            ) {
                results {
                    region {
                        id
                        name
                        geojson
                        resolution
                    }
                    stats: seasonal_stats(
                        filter: { init_time: $init_time, units: $units }
                    ) {
                        results {
                            tercile_probabilities
                            outlook_mean
                            historical {
                                yield_terciles
                            }
                        }
                    }
                }
            }
        }
    `,
}
const YieldOutlookMap = ({ filters, type, selectors }: IYieldOutlookWidgetProps) => {
    return (
        <WidgetWrapper
            selectedFilters={[
                "decade",
                "scenario",
                "region_ids",
                "asset_ids",
                "state_ids",
                "init_time",
            ]}
            component={ShadedRegionsMapWidget}
            query={queries[type]}
            filters={filters}
            selectors={selectors}
        />
    )
}
export default YieldOutlookMap
