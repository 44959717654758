import BelowIcon from "../../../icons/directionalityIcons/BelowIcon"
import WithinIcon from "../../../icons/directionalityIcons/WithinIcon"
import Color from "color"
import { CSSProperties, ReactNode } from "react"

const DEFAULT_COLOR = "#666D74"
const formatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 1,
})

const getRowDefaults = ({
    directionality: selectedDirectionality,
    personalizedColor,
    quantiles: _quantiles,
}: {
    directionality: number
    personalizedColor: string
    quantiles: Record<string, number>
}) => {
    const quantiles = Object.values(_quantiles)
    const defaultStyle = {
        borderRadius: "5px",
        border: "solid",
        backgroundColor: Color(personalizedColor).alpha(0.1).hexa(),
        color: Color(personalizedColor).darken(0.2).hex(),
        borderColor: Color(personalizedColor).alpha(0.4).hexa(),
    }
    return [
        {
            directionality: -1,
            icon: <BelowIcon />,
            style: -1 === selectedDirectionality ? defaultStyle : {},
            fill:
                -1 === selectedDirectionality
                    ? personalizedColor
                    : DEFAULT_COLOR,
            labelKey: "below",
            labelDefault: "Below",
            quantile: quantiles[0],
        },
        {
            directionality: 0,
            icon: <WithinIcon />,
            style: 0 === selectedDirectionality ? defaultStyle : {},
            fill:
                0 === selectedDirectionality
                    ? personalizedColor
                    : DEFAULT_COLOR,
            labelKey: "within",
            labelDefault: "Within",
            quantile: quantiles as [number, number],
        },
        {
            directionality: 1,
            icon: <BelowIcon className="rotate-180" />,
            style: 1 === selectedDirectionality ? defaultStyle : {},
            fill:
                1 === selectedDirectionality
                    ? personalizedColor
                    : DEFAULT_COLOR,
            labelKey: "above",
            labelDefault: "Above",
            quantile: quantiles[1],
        },
    ]
}
const DirectionalityHoverRow = ({
    icon,
    style,
    fill,
    labelKey,
    labelDefault,
    probability,
    quantile,
    units,
    translateFn = (key, fallback) => fallback ?? key,
}: {
    icon: ReactNode
    style: CSSProperties
    labelKey: string
    labelDefault: string
    fill: string
    quantile: number | [number, number]
    probability: number
    units: string
    translateFn?: (key: string, fallback?: string) => string
}) => {
    return (
        <div
            style={style}
            className="w-[100%] flex justify-between py-[5px] px-[10px] items-center border-b-[1px] border-solid border-[#DBDDDF] text-[#666D74]">
            <div className="flex items-center">
                <div
                    className="w-[18px] h-[18px]"
                    style={{
                        fill,
                    }}>
                    {icon}
                </div>
                <div className="ml-[12px]">
                    <h1 className="font-[600]">
                        {translateFn(labelKey, labelDefault) +
                            " " +
                            translateFn("normal", "normal")}
                    </h1>
                    <h1>
                        {typeof quantile === "number"
                            ? `${translateFn(
                                  labelKey,
                                  labelDefault
                              )} ${formatter.format(quantile)} ${units}`
                            : `${translateFn(
                                  "between",
                                  "Between"
                              )} ${formatter.format(
                                  quantile[0]
                              )} - ${formatter.format(quantile[1])} ${units}`}
                    </h1>
                </div>
            </div>
            <h1 className="font-bold text-[14px]">
                {probability !== undefined &&
                    formatter.format(probability * 100) + "%"}
            </h1>
        </div>
    )
}
const DirectionalityHover = ({
    personalizedColor,
    directionality,
    title,
    description,
    rightHeader,
    probabilities,
    quantiles,
    units,
    translateFn = (key, fallback) => fallback ?? key,
}: {
    personalizedColor: string
    directionality: number
    title: string
    description: string
    rightHeader: string
    probabilities: Record<string, number>
    quantiles: Record<string, number>
    units: string
    translateFn?: (key: string, fallback?: string) => string
}) => (
    <div className="flex flex-wrap w-[275px] h-[200px] font-roboto text-[12px]">
        <div className="w-full border-b-[1px] border-gray-14">
            <h1 className="font-bold title-[14px]">{title || ""}</h1>
            <div className="flex mb-[6px] w-[100%] justify-between text-gray-60">
                <h1>{description || ""}</h1>
                <h1 className="">{rightHeader || ""}</h1>
            </div>
        </div>
        {Object.values(probabilities).map((_, _idx, arr) => {
            // Reverses order to show above first in hover
            const idx = arr.length - 1 - _idx
            const prob = arr[idx]
            return (
                <DirectionalityHoverRow
                    {...getRowDefaults({
                        directionality,
                        personalizedColor,
                        quantiles,
                    })[idx]}
                    key={`${idx}_${prob}`}
                    probability={prob}
                    units={units}
                    translateFn={translateFn}
                />
            )
        })}
    </div>
)

export default DirectionalityHover
