import { useTranslate } from "@tolgee/react"
import { TooltipV2 } from "../../../climateui/components"
import { useLocale } from "../../../providers"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import { IStage } from "../../../types"
import { daysBetween } from "../../../utils"
import { formatDateLocalizedMonthNames } from "../../../utils/dates"

export const areSameDates = (d1: Date, d2: Date) => {
    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    )
}

export default function GDD({
    stage,
    gddForecastEndDate,
    gddsDict,
    isLoading,
    stagesStartDate,
}: {
    stage: IStage
    gddForecastEndDate: Date
    gddsDict: Record<string, number>
    isLoading: boolean
    stagesStartDate: Date
}) {
    const { pxPerDay } = useSeasonalCalendar()
    const { monthNames } = useLocale()
    const stageStartDate = stage.start_date ?? new Date()
    const stageEndDate = stage.end_date ?? new Date()
    const color = stage.color ?? ""

    const { t } = useTranslate()

    let value = ""
    let mainStyle: Record<string, string> = {}

    let beyondRange = false
    if (isLoading) {
        value = t("loading", "Loading...")
        mainStyle = {}
    } else if (stageStartDate >= gddForecastEndDate) {
        beyondRange = true
        value = t("beyondRange", "Beyond Range")
        mainStyle = {}
    } else {
        const actualEndDate =
            stageEndDate >= gddForecastEndDate
                ? gddForecastEndDate
                : stageEndDate
        const gdd_accum: number = gddsDict[actualEndDate.toDateString()]
        value =
            (gdd_accum !== null && gdd_accum !== undefined
                ? gdd_accum.toFixed(0)
                : 0) +
            " " +
            t("gdds", "GDDs")
        mainStyle = {
            left:
                (stageEndDate >= gddForecastEndDate
                    ? -daysBetween(gddForecastEndDate, stageEndDate) * pxPerDay
                    : "0") + "px",
        }
    }

    return (
        <div
            className={["absolute inset-y-0 z-30", "w-0"].join(" ")}
            style={mainStyle}>
            <div className="absolute right-0 inset-y-0 flex flex-row items-center">
                <TooltipV2
                    containerClasses="grow-0 shrink-0"
                    contentClass={beyondRange ? "w-44" : ""}
                    content={
                        beyondRange ? (
                            t(
                                "forecastRangeNoGDD",
                                "Forecast range extends 6 months from today. Data will update as time passes."
                            )
                        ) : (
                            <div className="">
                                <div className="">
                                    {t("expectedSumGDDs", "Expected Sum GDDs")}
                                </div>

                                <div className="">{value}</div>

                                <div className="">
                                    {t(
                                        "fromDATE1toDATE2",
                                        "From {date1} to {date2}",
                                        {
                                            date1: formatDateLocalizedMonthNames(
                                                stagesStartDate,
                                                monthNames
                                            ),
                                            date2: formatDateLocalizedMonthNames(
                                                stage.end_date ?? new Date(),
                                                monthNames
                                            ),
                                        }
                                    )}
                                </div>
                            </div>
                        )
                    }
                    position="bottom">
                    <div className="flex flex-row items-center h-6">
                        <div className="whitespace-nowrap label-sm text-gray-60 bg-white/90 pl-3 pr-2 rounded-sm">
                            {value}
                        </div>
                        <div className="w-1 h-full relative">
                            <div className="inset-0 absolute bg-white"></div>
                            <div
                                className="inset-0 absolute opacity-40"
                                style={{
                                    backgroundColor: color,
                                }}></div>
                        </div>
                    </div>
                </TooltipV2>
            </div>
        </div>
    )
}
