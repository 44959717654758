import { useTranslate } from "@tolgee/react"
import _ from "lodash"
import { useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useOutletContext, useSearchParams } from "react-router-dom"
import { IMapContext } from "."
import PaginatedTable from "../../../climateui/components/Table/PaginatedTable"
import { isValidResponse } from "../../../climateui/utils/http"
import { GenericPageHeader } from "../../../components"
import { useFlagValue, useMemoQuery } from "../../../hooks"
import { useAccount } from "../../../providers/AccountProvider"
import { LocationsContext } from "../../../providers/LocationsProvider"
import {
    ITrigger,
    IRiskProfile,
    IInsightsLocation,
} from "../../../types"
import { IFilter } from "../../../utils/filters"
import {
    alertQuerySet,
    locationListSearchGET,
    riskProfileQuerySet,
    triggeredAlertsPOST,
} from "../../../utils/networking"
import { arrToDict } from "../../../utils/transform"
import { AlertCell, DateNavigator } from "./components"
import AlertFilters, { IAlertFilters } from "./components/AlertFilters"
import { DateTime } from "luxon"
import { Row } from "@tanstack/react-table"
import { useAlerts, useLocale } from "../../../providers"
import { getFirstRelevantDate, getLastRelevantDate } from "./utils"
import { ALL, MONTHLY } from "./components/DateNavigator"
import { IMapPin } from "../../../climateui/components/Map/utils"
import { useAlertsMarkerPopup } from "./components/mapUtils"

interface IGroupedAlerts {
    riskProfile: IRiskProfile
    location: IInsightsLocation
    alerts: ITrigger[]
}

const TriggeredAlertsList = () => {
    /* CONSTS > START */
    const ALL_DEFAULT_COUNT = 50
    /* CONSTS < END */
    /* HOOKS > START */
    const { t } = useTranslate()
    const { selectedAccount, prevSelectedAccount } = useAccount()
    const { childLocations } = useContext(LocationsContext)
    const { mapPins, setMapPins, boundingBox } = useOutletContext<IMapContext>()
    const { getAlertsMarkerPopup } = useAlertsMarkerPopup()
    const { alertSettingsIDs, totalAlerts } = useAlerts()
    const { language } = useLocale()
    const fsAllDefaultCount = useFlagValue(
        "feature_alerts_all_default_count"
    ) as number | undefined
    const [, setQueryParams] = useSearchParams()
    /* HOOKS < END */

    /* STATE > START */
    const [filters, setFilters] = useState<IAlertFilters>({})
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 15,
    })
    /* STATE < END */

    /* STATE > MEMO > START */
    const riskProfilesFilterPayload = useMemo(() => {
        const defaultFilter: IFilter = {
            filter_by: {
                and: [],
            },
        }
        if (filters.selectedProfiles) {
            defaultFilter.filter_by.and?.push({
                field_name: "risk_profile.RiskProfile.id",
                operator: "in",
                field_value: JSON.stringify(
                    Object.keys(filters.selectedProfiles)
                ),
            })
        }
        if (filters.selectedLabels) {
            const labels = Object.keys(filters.selectedLabels)
            labels.forEach((label) =>
                defaultFilter.filter_by.and?.push({
                    field_name: "risk_profile.RiskProfile.labels",
                    operator: "any",
                    field_value: {
                        field_name: "label.Label.id",
                        operator: "in",
                        field_value: [label],
                    },
                })
            )
        }
        if (filters.selectedVariables) {
            defaultFilter.filter_by.and?.push({
                field_name: "risk_profile.RiskProfile.hazard_profiles",
                operator: "any",
                field_value: {
                    field_name:
                        "hazard_profile.HazardProfile.hazard_variable_id",
                    operator: "in",
                    field_value: Object.keys(filters.selectedVariables),
                },
            })
        }
        if (filters.selectedAssets) {
            const assets = Object.keys(filters.selectedAssets)
            defaultFilter.filter_by.and?.push({
                field_name: "risk_profile.RiskProfile.varieties",
                operator: "any",
                field_value: {
                    field_name:
                        "risk_profile_variety_association.RiskProfileVarietyAssociation.variety_id",
                    operator: "in",
                    field_value: assets,
                },
            })
        }
        return defaultFilter
    }, [filters, selectedAccount])

    const [riskProfiles] = useMemoQuery<Record<string, IRiskProfile>>(
        ["alertsRiskProfiles", riskProfilesFilterPayload, selectedAccount],
        () =>
            riskProfileQuerySet.post("/search", {
                ...riskProfilesFilterPayload,
                pagination: {
                    all: true,
                },
            }),
        {
            enabled: !!riskProfilesFilterPayload,
        },
        (data) =>
            arrToDict((data as { data: IRiskProfile[] }).data, "id") ??
            {},
        {}
    )
    const locationsFilterPayload = useMemo(() => {
        const defaultFilter: IFilter = {
            // TODO: Add support to retrieve child locations as well
            filter_by: {
                and: [
                    {
                        field_name: "location.Location.account_id",
                        operator: "eq",
                        field_value: selectedAccount,
                    },
                ],
            },
        }
        if (filters.selectedLocations) {
            defaultFilter.filter_by.and?.push({
                field_name: "location.Location.id",
                operator: "in",
                field_value: JSON.stringify(
                    Object.keys(filters.selectedLocations)
                ),
            })
        }
        return defaultFilter
    }, [filters, selectedAccount])

    const [locations] = useMemoQuery<Record<string, IInsightsLocation>>(
        ["alertsLocations", locationsFilterPayload, filters.selectedRegions],
        () =>
            locationListSearchGET({
                ...locationsFilterPayload,
                pagination: {
                    all: true,
                },
            }),
        {
            enabled: !!locationsFilterPayload,
        },
        (data) =>
            ({
                ...arrToDict(
                    (data as { data: IInsightsLocation[] }).data.filter(
                        (location) => {
                            if (filters.selectedRegions) {
                                const selectedRegions = Object.keys(
                                    filters.selectedRegions
                                )
                                if (location.region && location.region.id) {
                                    return selectedRegions.includes(
                                        location.region.id
                                    )
                                }
                                if (location.regions) {
                                    return location.regions.some((region) => {
                                        if (region.id)
                                            return selectedRegions.includes(
                                                region.id
                                            )
                                    })
                                }
                            }
                            return true
                        }
                    ),
                    "id"
                ),
                ...arrToDict(childLocations || [], "id"),
            } ?? {}),
        {}
    )
    const canFetchAlerts = useMemo(
        () =>
            Object.keys(riskProfiles ?? {}).length > 0 &&
            Object.keys(locations ?? {}).length > 0 &&
            (alertSettingsIDs ?? []).length > 0 &&
            selectedAccount,
        [selectedAccount, locations, riskProfiles, alertSettingsIDs]
    )
    /* STATE > MEMO < END */

    /* NETWORK > START */
    const alertsFilterPayload = useMemo(() => {
        const defaultFilter: IFilter = {
            filter_by: {
                and: [
                    {
                        field_name: "processing_run.ProcessingRun.account_id",
                        operator: "eq",
                        field_value: selectedAccount,
                    },
                    {
                        field_name:
                            "processing_run.ProcessingRun.risk_setting_id",
                        operator: "in",
                        field_value: JSON.stringify(alertSettingsIDs),
                    },
                    {
                        field_name: "alert.Alert.category",
                        operator: "ne",
                        field_value: 'No Action',
                    }
                ],
            },
        }
        if (filters.selectedStartDate && filters.selectedEndDate) {
            defaultFilter.filter_by.and?.push({
                field_name: "alert.Alert.start_date",
                operator: "lte",
                field_value: filters.selectedEndDate.toUTC().toISO(),
            })
            defaultFilter.filter_by.and?.push({
                field_name: "alert.Alert.end_date",
                operator: "gte",
                field_value: filters.selectedStartDate.toUTC().toISO(),
            })
        }
        if (filters.minProbability) {
            defaultFilter.filter_by.and?.push({
                field_name: "alert.Alert.avg_risk_value",
                operator: "gte",
                field_value: filters.minProbability,
            })
        }
        return defaultFilter
    }, [selectedAccount, filters, alertSettingsIDs])
    const {
        data: alertsResponse,
        isLoading,
        isRefetching,
    } = useQuery({
        queryKey: ["alerts", canFetchAlerts, alertsFilterPayload, language],
        queryFn: () => {
            return triggeredAlertsPOST({
                ...alertsFilterPayload,
                order_by: ["-processing_run.ProcessingRun.ran_at"],
                pagination: {
                    all: true,
                },
            })
        },
        enabled: !!canFetchAlerts,
    })
    const { data: alertsDates } = useQuery({
        queryKey: ["alertsDates", selectedAccount],
        queryFn: () =>
            alertQuerySet.post({
                path: "/search",
                config: {
                    headers: {
                        "X-Fields": "{data{alerts{start_date,end_date}}}",
                    },
                },
            }),
        select: (response) => {
            if (!isValidResponse(response)) return []
            const groupedAlerts = response.data.data as {
                alerts: {
                    start_date: string
                    end_date: string
                }[]
            }[]
            return groupedAlerts.map(({ alerts }) => alerts).flat()
        },
    })
    const [alerts, setAlerts] = useState<IGroupedAlerts[]>([])
    const [firstAlertDate, setFirstAlertDate] = useState<DateTime>()
    const [lastAlertDate, setLastAlertDate] = useState<DateTime>()

    useEffect(() => {
        if (!isValidResponse(alertsResponse)) {
            setMapPins([])
            setAlerts([])
            return
        }
        const { data: _data } = alertsResponse
        const data = _data.data as ITrigger[]
        const _mapPins: Record<string, IMapPin> = {}
        const _alerts: Record<string, IGroupedAlerts> = {}

        data.forEach((trigger) => {
            const location = locations[trigger.processing_run.location_id]
            const riskProfile = riskProfiles[trigger.processing_run.risk_id]
            if (!location || !riskProfile) return
            if (location) {
                // Add the pin for this location
                const key = `${location.longitude},${location.latitude}`
                if (!_mapPins[key]) {
                    _mapPins[key] = {
                        lon: location.longitude,
                        lat: location.latitude,
                        id: location.id,
                        pinStyle: "map-alert-pin.png",
                        data: {
                            location,
                            riskProfiles: {
                                [riskProfile.id]: riskProfile,
                            },
                            alerts: _alerts,
                        },
                        events: (marker) => ({
                            click: () => marker.togglePopup(),
                        }),
                        getCount: (pin) => {
                            return Object.keys(pin.data.riskProfiles).length
                        },
                        popup: getAlertsMarkerPopup,
                    }
                } else {
                    const mapPin = _mapPins[key]
                    if (mapPin.data.riskProfiles) {
                        mapPin.data.riskProfiles[riskProfile.id] = riskProfile
                    }
                }
            }
            // If the alert is not part of the bounding box, don't include it
            if (boundingBox) {
                if (
                    location.longitude > boundingBox.getEast() ||
                    location.longitude < boundingBox.getWest() ||
                    location.latitude > boundingBox.getNorth() ||
                    location.latitude < boundingBox.getSouth()
                )
                    return
            }
            // else, add it to the list
            const groupKey = `${riskProfile.id}-${location.id}`
            if (_alerts[groupKey]) {
                _alerts[groupKey].alerts.push(trigger)
            } else {
                _alerts[groupKey] = {
                    riskProfile,
                    location,
                    alerts: [trigger],
                }
            }
        })

        const _mapPinsVals = Object.values(_mapPins)
        // Set the map pins
        if (data.length === 0) setMapPins([])
        else if (!_.isEqual(_mapPinsVals, mapPins)) setMapPins(_mapPinsVals)

        const alertValues = Object.values(_alerts)
        setAlerts(alertValues)
    }, [alertsResponse, riskProfiles, locations])

    useEffect(() => {
        if (!isValidResponse(alertsResponse) || !riskProfiles || !alerts) return
        const { data: _data } = alertsResponse
        const data = _data.data as ITrigger[]
        const _alerts: Record<string, IGroupedAlerts> = {}
        data.forEach((trigger) => {
            const location = locations[trigger.processing_run.location_id]
            const riskProfile = riskProfiles[trigger.processing_run.risk_id]
            if (!location || !riskProfile) return
            // If the alert is not part of the bounding box, don't include it
            if (boundingBox) {
                if (
                    location.longitude > boundingBox.getEast() ||
                    location.longitude < boundingBox.getWest() ||
                    location.latitude > boundingBox.getNorth() ||
                    location.latitude < boundingBox.getSouth()
                )
                    return
            }
            // else, add it to the list
            const groupKey = `${riskProfile.id}-${location.id}`
            if (_alerts[groupKey]) {
                _alerts[groupKey].alerts.push(trigger)
            } else {
                _alerts[groupKey] = {
                    riskProfile,
                    location,
                    alerts: [trigger],
                }
            }
        })
        const alertValues = Object.values(_alerts)
        setAlerts(alertValues)
    }, [boundingBox])

    // Set the alerts dates
    useEffect(() => {
        if (!alertsDates) {
            setFirstAlertDate(undefined)
            setLastAlertDate(undefined)
        } else {
            setFirstAlertDate(getFirstRelevantDate(alertsDates))
            setLastAlertDate(getLastRelevantDate(alertsDates))
        }
    }, [alertsDates])

    // Clear params
    useEffect(() => {
        if (!selectedAccount || !prevSelectedAccount) return
        if (selectedAccount !== prevSelectedAccount) setQueryParams()
    }, [selectedAccount, prevSelectedAccount])

    useEffect(() => {
        setPagination({
            ...pagination,
            page: 1,
        })
    }, [alerts])
    /* NETWORK< END */

    /* Populate filters based on query params */

    return (
        <div className="flex flex-col justify-start h-full px-8 py-4 gap-2">
            {(isLoading || isRefetching) && (
                <div className="absolute left-0 loading-screen__loader" />
            )}
            <div className="grow-0">
                <GenericPageHeader
                    pageTitle={t(
                        "alertsTriggeredCOUNT",
                        "Alerts Triggered ({count})",
                        { count: alerts.length }
                    )}
                    right={<div></div>}
                    bottom={
                        <AlertFilters
                            onFilterChange={(newFilters) => {
                                setFilters({
                                    ...newFilters,
                                    selectedStartDate:
                                        filters.selectedStartDate,
                                    selectedEndDate: filters.selectedEndDate,
                                })
                            }}
                        />
                    }
                />
            </div>
            <div className="h-[60%] pb-2 md:pb-0 lg:max-h-[80%] grow">
                <DateNavigator
                    onChange={(
                        selectedStartDate: DateTime,
                        selectedEndDate: DateTime
                    ) => {
                        setFilters({
                            ...filters,
                            selectedStartDate,
                            selectedEndDate,
                        })
                    }}
                    isLoading={isLoading}
                    initialDate={firstAlertDate}
                    defaultMode={
                        totalAlerts >= (fsAllDefaultCount || ALL_DEFAULT_COUNT)
                            ? MONTHLY
                            : ALL
                    }
                    minDate={firstAlertDate}
                    minDateTooltip={t(
                        "noAlertsBefore",
                        "No alerts before this range"
                    )}
                    maxDate={lastAlertDate}
                    maxDateTooltip={t(
                        "noAlertsAfter",
                        "No alerts beyond this range"
                    )}
                />
                <PaginatedTable<IGroupedAlerts>
                    hideHeaders={true}
                    onPageChange={({ page, pageSize: per_page }) => {
                        setPagination({
                            page,
                            per_page,
                        })
                    }}
                    totalRecords={alerts.length}
                    initialPage={pagination.page}
                    initialPageSize={pagination.per_page}
                    data={alerts.slice(
                        (pagination.page - 1) * pagination.per_page,
                        pagination.page * pagination.per_page
                    )}
                    columns={[
                        {
                            id: "info",
                            cell: ({
                                row,
                            }: {
                                row: Row<IGroupedAlerts>
                            }) => <AlertCell {...row.original} />,
                        },
                    ]}
                    getRowId={(original) =>
                        `${original.riskProfile.id}-${original.location.id}`
                    }
                    noDataMessage={t(
                        "noAlertsToday",
                        "There are no alerts for today"
                    )}
                    outOfText={t("of", "of")}
                    pageSizeOpts={{
                        15: `15 ${t("alertsLabel", "alerts").toLowerCase()}`,
                        30: `30 ${t("alertsLabel", "alerts").toLowerCase()}`,
                        50: `50 ${t("alertsLabel", "alerts").toLowerCase()}`,
                        100: `100 ${t("alertsLabel", "alerts").toLowerCase()}`,
                    }}
                />
            </div>
        </div>
    )
}
export default TriggeredAlertsList
