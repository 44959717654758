import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ChevronIcon from "../../../../climateui/icons/ChevronArrow"
import { DateTime } from "luxon"
import { TooltipV2 } from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { useSearchParams } from "react-router-dom"
import { useFlagValue } from "../../../../hooks"
import { SingleDropdownSelect } from "../../../../climateui/components/Inputs"
import { CalendarIcon } from "../../../../climateui/icons"
import { getTimeWindowByGranularity } from "../utils"

/* CONSTS > START */
export const NEXTTWOWEEKS = 0
export const NEXTSIXWEEKS = 1
export const NEXTSIXMONTHS = 2
const ALL_DEFAULT_MONTH_OFFSET = 7
const scope: { [key: number]: string } = {
    [NEXTTWOWEEKS]: "daily",
    [NEXTSIXWEEKS]: "weekly",
    [NEXTSIXMONTHS]: "monthly",
}
/* CONSTS < END */

interface IAlertDateNavigatorProps {
    onChange?: (startDate: DateTime, endDate: DateTime) => void
    initialDate?: DateTime
    defaultMode?: number
    maxDate?: DateTime
    minDate?: DateTime
    isLoading?: boolean
    setScope?: (scope: number) => void
}
const AlertDateNavigator: React.FC<IAlertDateNavigatorProps> = ({
    onChange,
    initialDate,
    defaultMode = NEXTTWOWEEKS,
    maxDate,
    minDate,
    setScope,
}) => {
    /* CONSTS > START */
    const START_DATE = DateTime.now().startOf("day")
    const END_DATE = START_DATE.endOf("year")
    /* CONSTS < END */

    const { t } = useTranslate()
    const fsAllDefaultMonthOffset = useFlagValue(
        "feature_alerts_all_default_month_offset"
    ) as number | undefined

    /* STATE > START */
    const [queryParams, setQueryParams] = useSearchParams()
    const queryStart = queryParams.get("start")
    const queryStartDate = useRef(
        queryStart ? DateTime.fromISO(queryStart) : undefined
    )
    const queryEnd = queryParams.get("end")
    const queryEndDate = useRef(
        queryEnd ? DateTime.fromISO(queryEnd) : undefined
    )
    const isValidQueryDate = !!queryStartDate.current && !!queryEndDate.current

    const [timeScope, setTimeScope] = useState(defaultMode)

    const [startDate, setStartDate] = useState<DateTime>(
        queryStartDate.current ?? START_DATE
    )
    const [endDate, setEndDate] = useState<DateTime>(
        queryEndDate.current ?? END_DATE
    )

    const rangeStr = useMemo(() => {
        return `${startDate.toFormat("MMM d")} - ${endDate.toFormat("MMM d")}`
    }, [timeScope, startDate, endDate])

    /* STATE < END */

    /* FUNCS > START */
    const getDefaults = (_mode: number) => {
        if (!initialDate) return [START_DATE, END_DATE]
        // const today = DateTime.now().startOf("day")
        switch (_mode) {
            case NEXTSIXMONTHS:
                return getTimeWindowByGranularity("monthly")
            case NEXTTWOWEEKS:
                return getTimeWindowByGranularity("daily")
            case NEXTSIXWEEKS:
                return getTimeWindowByGranularity("weekly")
            default:
                return getTimeWindowByGranularity("daily")
        }
    }
    const restoreDefault = (_mode: number) => {
        const [defaultStart, defaultEnd] = getDefaults(_mode)
        setStartDate(defaultStart)
        setEndDate(defaultEnd)
    }
    const setMode = (_mode: number) => {
        if (_mode !== timeScope) {
            restoreDefault(_mode)
            setTimeScope(_mode)
        }
    }
    /* FUNC DEPENDENT STATE > START */

    useEffect(() => {
        if (!isValidQueryDate) {
            restoreDefault(timeScope)
        }
    }, [initialDate, isValidQueryDate, timeScope])

    /* FUNCS < END */
    /* LIFECYCLE HOOKS > START */
    useEffect(() => {
        if (!isValidQueryDate) {
            restoreDefault(timeScope)
        }
    }, [initialDate])
    useEffect(() => {
        if (!isValidQueryDate) setMode(defaultMode)
    }, [defaultMode])

    useEffect(() => {
        if (onChange) {
            onChange(startDate, endDate)
        }
        queryParams.set("start", startDate.toISODate())
        queryParams.set("end", endDate.toISODate())
        queryParams.set("scope", scope[timeScope])
        setQueryParams(queryParams)
    }, [startDate, endDate, timeScope])
    /* LIFECYCLE HOOKS < END */

    const updateSearchParams = (
        newStartDate: DateTime,
        newEndDate: DateTime
    ) => {
        const newSearchParams = new URLSearchParams()
        newSearchParams.set("start", newStartDate.toISO())
        newSearchParams.set("end", newEndDate.toISO())
        setQueryParams(newSearchParams)
    }

    const navigateDate = (direction: number) => {
        const newMode = (timeScope + direction + 3) % 3
        if (!isNaN(newMode)) {
            setMode(newMode)
            setScope?.(newMode)
        }
        const [newStartDate, newEndDate] = getDefaults(newMode)
        setStartDate(newStartDate)
        setEndDate(newEndDate)
        updateSearchParams(newStartDate, newEndDate)
    }
    const goBack = () => {
        navigateDate(-1)
    }
    const goForward = () => {
        navigateDate(1)
    }

    const handleSelectMode = useCallback(
        (newSelected: any) => {
            const newMode = Number.parseInt(newSelected)
            if (!isNaN(newMode)) {
                setMode(newMode)
                setScope?.(newMode)
            }
        },
        [setMode, setScope]
    )

    return (
        <TooltipV2
            content={rangeStr}
            position="top">
            <div className="flex items-center bg-white border rounded-sm gap-1 border-gray-14">
                <div className="flex items-center mx-1">
                    {/* BACK ARROW */}
                    <button
                        onClick={goBack}
                        className="w-5 h-5 cursor-pointer transform rotate-90">
                        <ChevronIcon fill="#666D74" />
                    </button>
                    {/* FORWARD ARROW */}
                    <button
                        onClick={goForward}
                        className="w-5 h-5 cursor-pointer transform -rotate-90">
                        <ChevronIcon fill="#666D74" />
                    </button>
                </div>
                <SingleDropdownSelect
                    options={{
                        [NEXTTWOWEEKS]: t("daily"),
                        [NEXTSIXWEEKS]: t("weekly"),
                        [NEXTSIXMONTHS]: t("monthly"),
                    }}
                    selected={timeScope.toString()}
                    setSelected={handleSelectMode}
                    trailingIcon={<CalendarIcon />}
                />
            </div>
        </TooltipV2>
    )
}

export default AlertDateNavigator
