import { useState, useContext, useMemo, useEffect } from "react"
import { useTranslate } from "@tolgee/react"
import { useRiskProfiles } from "../../../../../providers/RiskProfilesProvider"
import { LocationsContext } from "../../../../../providers/LocationsProvider"
import { IBooleanDictionary } from "../../../../../climateui/types"
import { useSearchParams } from "react-router-dom"
import { IAlertFilters } from "../AlertFilters"
import { MultiDropdownSelect } from "../../../../../climateui/components/Inputs"
import { FilterIcon } from "../../../../../climateui/icons"

const AlertPageFilters = ({
    onFilterChange,
}: {
    onFilterChange: (filters: IAlertFilters) => void
}) => {
    const { t } = useTranslate()
    const { riskProfiles: _riskProfiles } = useRiskProfiles()
    const riskProfiles = useMemo(() => _riskProfiles ?? [], [_riskProfiles])
    const { locations } = useContext(LocationsContext)

    const [queryParams, setQueryParams] = useSearchParams()
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const [selectedRegions, setSelectedRegions] = useState<IBooleanDictionary>(
        {}
    )

    const [selectedVariables, setSelectedVariables] =
        useState<IBooleanDictionary>({})

    const [selectedAssets, setSelectedAssets] = useState<IBooleanDictionary>({})
    const [selectedCategory] = useState<IBooleanDictionary>({})

    const regionOpts = useMemo(() => {
        const _regions: Record<string, string> = {}
        locations.forEach((location) => {
            if (location.region?.id)
                _regions[location.region.id] = location.region.name
            else if (location.regions)
                location.regions.forEach((region) => {
                    if (region.id) _regions[region.id] = region.name
                })
        })
        return _regions
    }, [locations])

    const hazardVariableOpts = useMemo(() => {
        const options: Record<string, string> = {}
        riskProfiles.forEach((riskProfile) => {
            riskProfile.hazard_profiles.forEach((hazardProfile) => {
                const hazardVariable = hazardProfile.hazard_variable
                if (!options[hazardVariable.id]) {
                    options[hazardVariable.id] = hazardVariable.readable_name
                }
            })
        })
        return options
    }, [riskProfiles])

    const assetsOpts = useMemo(() => {
        const _assets: Record<string, string> = {}
        locations.forEach((location) => {
            location.varieties?.forEach(variety => {
                if (variety.id && variety.name) {
                    _assets[variety.id] = `${variety.asset.name} (${variety.name})`
                }
            })
        })
        return _assets
    }, [locations])

    const arrToFilterVal = (_arr: string | string[]) => {
        const arr = typeof _arr === "string" ? [_arr] : _arr
        return arr.reduce(
            (prev: Record<string, boolean>, curr: string) => ({
                ...prev,
                [curr]: true,
            }),
            {}
        )
    }
    const parseQueryParam = (param: string) => JSON.parse(decodeURI(param))
    useEffect(() => {
        if (!isInitialLoad) return
        const region = queryParams.get("region")
        const variable = queryParams.get("variable")
        const asset = queryParams.get("asset")

        if (region) setSelectedRegions(arrToFilterVal(parseQueryParam(region)))
        if (variable)
            setSelectedVariables(arrToFilterVal(parseQueryParam(variable)))
        if (asset) setSelectedAssets(arrToFilterVal(parseQueryParam(asset)))
        setIsInitialLoad(false)
    }, [queryParams])

    const serializeFilters = ({
        selectedRegions,
        selectedVariables,
        selectedStartDate,
        selectedEndDate,
        selectedAssets,
    }: IAlertFilters) => {
        const _queryParams: Record<string, string | string[]> = {}
        if (selectedRegions)
            _queryParams["region"] = JSON.stringify(
                Object.keys(selectedRegions)
            )

        if (selectedVariables)
            _queryParams["variable"] = JSON.stringify(
                Object.keys(selectedVariables)
            )

        if (selectedStartDate) _queryParams["start"] = selectedStartDate.toISO()
        if (selectedEndDate) _queryParams["end"] = selectedEndDate.toISO()
        if (selectedAssets)
            _queryParams["asset"] = JSON.stringify(Object.keys(selectedAssets))
        return _queryParams
    }

    useEffect(() => {
        // Skips the filters that have not being applied
        const filterStates = {
            selectedRegions,
            selectedVariables,
            selectedAssets,
            selectedCategory,
        }
        let filters: IAlertFilters = {}
        Object.entries(filterStates).forEach(([key, state]) => {
            if (Object.keys(state).length) {
                filters = {
                    ...filters,
                    [key]: state,
                }
            }
        })
        onFilterChange(filters)
        // Create candidate for query params
        const _queryParams = {
            ...serializeFilters(filters),
        }
        // Extract possible query params dates
        const _start = queryParams.get("start")
        const _end = queryParams.get("end")
        // If the start date query param exists, include it
        if (_start) {
            _queryParams["start"] = _start
        }
        // If the end date query param exists, include it
        if (_end) {
            _queryParams["end"] = _end
        }
        // Set the new query params
        setQueryParams(_queryParams)
    }, [selectedRegions, selectedVariables, selectedAssets, selectedCategory])

    return (
        <div className="flex flex-row items-center justify-start gap-x-2">
            <MultiDropdownSelect
                options={
                    {
                        ...assetsOpts,
                    } as { [key: string]: string }
                } selected={selectedAssets}
                setSelected={setSelectedAssets}
                placeholder={t("asset", "Asset")}
                trailingIcon={<FilterIcon />}
                canClear={true}
                searchMin={1}
                sort="lexical-by-value"
            />
            <MultiDropdownSelect
                options={regionOpts}
                selected={selectedRegions}
                setSelected={setSelectedRegions}
                placeholder={t("region", "Region")}
                trailingIcon={<FilterIcon />}
                canClear={true}
                searchMin={1}
                sort="lexical-by-value"
            />
            <MultiDropdownSelect
                options={hazardVariableOpts}
                selected={selectedVariables}
                setSelected={setSelectedVariables}
                placeholder={t("variable", "Variable")}
                trailingIcon={<FilterIcon />}
                canClear={true}
                searchMin={1}
                sort="lexical-by-value"
            />
        </div>
    )
}

export default AlertPageFilters
