import {
    IHazardProfile,
    IInsightsLocation,
    IRiskProfile,
    ITrigger,
} from "../../../../types"
import { DateTime } from "luxon"
import { RiskIconHandler } from "../../../../climateui/icons/riskIcons"
import LabelItem from "../../../../climateui/components/Labels/LabelItem"
import { useTranslate } from "@tolgee/react"
import { useContext, useMemo, useState } from "react"
import { Tooltip } from "../../../../climateui/components"
import { UnitConversionContext } from "../../../../providers/UnitConversionProvider"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import { Link, useNavigate } from "react-router-dom"
import { formatDate } from "../../../../utils/transform"
import { useAuth } from "../../../../providers"
import { useAccount } from "../../../../providers/AccountProvider"

const numberFormatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
})

const parseProbability = (probability?: number) => {
    if (!probability) return "n/a"
    return `${numberFormatter.format(probability * 100)}%`
}
const parseConditional = (hazardProfile: IHazardProfile) => {
    switch (hazardProfile.conditional.toLowerCase()) {
        case "less than":
        case "less":
        case "lt":
            return "<"
        case "more than":
        case "more":
        case "gt":
            return ">"
        case "equal":
        case "eq":
            return "="
        default:
            return hazardProfile.conditional
    }
}
const useParseUnit = () => {
    const { getUnit } = useContext(UnitConversionContext)
    return (hazardProfile: IHazardProfile) => {
        // TODO: Use user info to return either metric or imperial
        return hazardProfile.type.toLowerCase() === "relative"
            ? "%"
            : getUnit(hazardProfile.hazard_variable)
    }
}
const useParseThreshold = () => {
    // TODO: Get the locale
    const { convertUnits } = useContext(UnitConversionContext)
    return (hazardProfile: IHazardProfile) => {
        return numberFormatter.format(
            hazardProfile.type.toLowerCase() === "relative"
                ? hazardProfile.threshold * 100
                : convertUnits(
                      hazardProfile.threshold,
                      hazardProfile.hazard_variable,
                      "convertUnits"
                  ) ?? NaN
        )
    }
}
const Hazards = ({
    hazardProfiles,
}: {
    hazardProfiles?: IHazardProfile[]
}) => {
    const { t } = useTranslate()
    const parseThreshold = useParseThreshold()
    const parseUnit = useParseUnit()
    if (!hazardProfiles || hazardProfiles.length === 0) return <></>
    const isStacked = hazardProfiles.length > 1
    const hazardProfile = hazardProfiles[0]

    const parseHazardValue = () => {
        if (isStacked) return <>{t("stacked", "Stacked")}</>
        return (
            <span>
                {parseConditional(hazardProfile)}
                {parseThreshold(hazardProfile)}
                {parseUnit(hazardProfile)}
            </span>
        )
    }
    const parseName = () => {
        if (isStacked)
            return `${hazardProfiles.flat().length} ${t("risks", "Risks")}`
        return t(hazardProfile.hazard_variable.readable_name)
    }
    return (
        <div
            key={hazardProfile.id}
            className="flex flex-col items-end">
            <div className="flex flex-col items-end gap-1">
                {/* HAZARD > ICON > START */}
                <span className="block w-6 h-6 fill-gray-60">
                    <RiskIconHandler hazardProfiles={hazardProfiles} />
                </span>
                {/* HAZARD > ICON < END */}
                {/* HAZARD > VALUE > START */}
                {parseHazardValue()}
                {/* HAZARD > VALUE < END */}
            </div>
            {/* HAZARD > VARIABLE > START */}
            <span className="text-xs font-medium text-right text-gray-60 whitespace-nowrap">
                {parseName()}
            </span>
            {/* HAZARD > VARIABLE < END */}
        </div>
    )
}
const HazardProfiles = ({
    riskProfile,
}: {
    riskProfile?: IRiskProfile
}) => {
    if (!riskProfile || riskProfile.hazard_profiles.length === 0) return <></>
    return <Hazards hazardProfiles={riskProfile.hazard_profiles} />
}
const sortByDate = (
    { alert: a }: ITrigger,
    { alert: b }: ITrigger
) => {
    if (a.start_date > b.start_date) return 1
    if (a.start_date < b.start_date) return -1
    return 0
}
const AlertCell = (props: {
    alerts: ITrigger[]
    location: IInsightsLocation
    riskProfile: IRiskProfile
}) => {
    const { alerts, location, riskProfile: _riskProfile } = props
    const { riskProfilesObj, setCurrentRiskProfileById, setIsFormOpen } =
        useRiskProfiles()
    const riskProfile = riskProfilesObj[_riskProfile.id]
    const { t } = useTranslate()
    const { hasRole } = useAuth()
    const { selectedAccount } = useAccount()
    const [showMore, setShowMore] = useState(true)
    const navigate = useNavigate()
    const avgProb =
        alerts.reduce(
            (prev: number, curr: ITrigger) =>
                prev + curr.alert.avg_risk_value,
            0
        ) / alerts.length
    const isAdmin = useMemo(
        () => !!selectedAccount && hasRole(selectedAccount, "Admin"),
        [selectedAccount]
    )

    return (
        <div className="flex flex-row justify-between pt-3 pb-2">
            <div className="flex flex-col">
                {/* LOCATION & RISK PROFILE*/}
                <div className="font-medium">
                    <Link
                        to={`/seasonal/dashboards/location?locId=${location.id}`}>
                        <span className="underline">{location?.name}</span> •{" "}
                    </Link>
                    <span className="inline-block">
                        <Tooltip
                            doShow={isAdmin}
                            content={t(
                                "goToRiskProfile",
                                "Go to Risk Profile"
                            )}>
                            <span
                                onClick={() => {
                                    if (!isAdmin) return
                                    setCurrentRiskProfileById(riskProfile.id)
                                    setIsFormOpen(true)
                                    navigate("/admin/risk-profiles")
                                }}
                                role="button"
                                className={
                                    isAdmin
                                        ? "underline text-accent"
                                        : "cursor-default"
                                }>
                                {riskProfile?.name}
                            </span>
                        </Tooltip>
                    </span>
                </div>
                {/* DURATION & PROB */}
                {!showMore && alerts.length > 1 && (
                    <div className="flex flex-row gap-2 text-gray-60">
                        <div className="text-gray">
                            <span className="font-medium">{alerts.length}</span>{" "}
                            {t("timesTriggered", "Times triggered")}
                        </div>
                        <button
                            className="underline cursor-pointer"
                            onClick={() => setShowMore(true)}>
                            {t("showMore", "Show more")}
                        </button>
                    </div>
                )}
                {(showMore || alerts.length === 1) && (
                    <div>
                        {alerts.sort(sortByDate).map(({ alert }) => (
                            <span
                                key={alert.id}
                                className="relative flex flex-row gap-1 text-gray-60 whitespace-nowrap">
                                {formatDate(alert.start_date)}{" "}
                                <Tooltip
                                    position="right"
                                    doShow={true}
                                    content={`${t(
                                        "until",
                                        "Until"
                                    )} ${formatDate(
                                        DateTime.fromISO(alert.end_date)
                                    )}`}>
                                    <span className="cursor-help">
                                        ({alert.duration} {t("days", "days")}) -{" "}
                                        {t("probabilityAbbreviation", "Prob.")}{" "}
                                        {parseProbability(alert.avg_risk_value)}
                                    </span>
                                </Tooltip>
                            </span>
                        ))}
                        {showMore && alerts.length > 1 && (
                            <button
                                className="underline cursor-pointer text-gray-60"
                                onClick={() => setShowMore(false)}>
                                {t("showLess", "Show less")}
                            </button>
                        )}
                    </div>
                )}
                {/* LABELS */}
                <div className="relative flex flex-row flex-wrap mt-1 w-42 gap-1">
                    {riskProfile?.labels.map((label) => (
                        <span key={label.id}>
                            <LabelItem label={label} />
                        </span>
                    ))}
                    {riskProfile?.varieties.map((variety, idx, varieties) => {
                        return (
                            <span
                                key={variety.id}
                                className="body-sm text-gray-60">
                                {variety.asset.name} ({variety.name})
                                {idx < varieties.length - 1 && ", "}
                            </span>
                        )
                    })}
                </div>
            </div>
            {/* HAZARD PROFILES */}
            <div className="flex flex-col justify-center px-1 text-right">
                <HazardProfiles riskProfile={riskProfile} />
                {!showMore && (
                    <span className="text-xs text-gray-60">
                        {t("probabilityAbbreviation", "Prob.")}{" "}
                        {parseProbability(avgProb)}
                    </span>
                )}
            </div>
            {/**/}
        </div>
    )
}

export default AlertCell
