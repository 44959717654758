import React, { useContext } from "react"
import {
    IAlert, IHazardProfile,
    IInsightsLocation,
    IRiskProfile,
    IVariety,
} from "../../../../types"
import { useTranslate } from "@tolgee/react"
import {
    formatter,
    getProbabilityLabel,
    getMagnitudeLabel,
    formatPercentile,
    formatDateRange,
    TimeResolutionValues,
    formatConditionDescription,
    formatHazardDescription,
    getMagnitudeDescription,
    sortByDate,
    formatOccurrence,
} from "../utils"
import { CalendarIcon, CancelIcon } from "../../../../climateui/icons"
import MagnitudeIcon from "../../../../climateui/icons/MagnitudeIcon"
import PercentageIcon from "../../../../climateui/icons/PercentageIcon"
import HistoryIcon from "../../../../climateui/icons/HistoryIcon"
import RiskProfileDetailCard from "./RiskProfileDetailCard"
import DefauktRiskIcon from "../../../../climateui/icons/riskIcons/DefaultRiskIcon"
import { alertCategoriesEnum } from "./mapUtils"
import {
    UnitConversionContext,
    useUnits,
} from "../../../../providers/UnitConversionProvider"
import { useAssets } from "../../../../providers"
import { useNavigate } from "react-router-dom"

interface ISidePanelProps {
    isPanelOpen?: boolean
    panelContent: any
    availableVarietiesPerAlert: Record<string, string[]>
    closePanel?: () => void
    selectedCategory?: string | null
    scope?: number
}

export interface IAlertDetailCardData {
    icon: JSX.Element
    textLines: string[]
}

interface InformationInAlertCard {
    dateRange?: IAlertDetailCardData
    magnitude?: IAlertDetailCardData
    probability?: IAlertDetailCardData
    occurrence?: IAlertDetailCardData
    hazardProfileDescription?: IAlertDetailCardData
    locationName?: IAlertDetailCardData
    assets?: IAlertDetailCardData
}

export interface IPanelContent {
    alert: IAlert
    riskProfile: IRiskProfile
    location: IInsightsLocation
    alertDetailInfo: InformationInAlertCard
}

const SidePanel: React.FC<ISidePanelProps> = ({
    isPanelOpen,
    panelContent,
    availableVarietiesPerAlert,
    closePanel,
    selectedCategory,
    scope,
}) => {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { varieties } = useAssets()
    const { convertUnits } = useUnits()
    const { getUnit } = useContext(UnitConversionContext)
    const numFormatter = Intl.NumberFormat(undefined, {
        maximumFractionDigits: 1,
    })

    const locationVarieties: string[] = []
    if (varieties && panelContent[0].location) {
        const currentLocation: IInsightsLocation = panelContent[0].location
        currentLocation.varieties.forEach((variety) => {
            const varietyObj: IVariety =
                typeof variety === "string" ? varieties[variety] : variety
            if (varietyObj?.asset?.name && varietyObj?.name) {
                locationVarieties.push(
                    `${varietyObj.asset.name} (${varietyObj.name})`
                )
            }
        })
    }

    const filteredContent: IPanelContent[] = panelContent.filter(
        ({ riskProfile }: { riskProfile: IRiskProfile | undefined }) =>
            riskProfile !== undefined
    )
    filteredContent.sort(sortByDate)
    filteredContent.forEach((item) => {
        const probabilityPercentage = formatter.format(
            item.alert.avg_risk_value
        )
        const probabilityLabel = getProbabilityLabel(
            item.alert.avg_risk_value * 100
        )
        const magnitudeLabel = item.alert.expected_percentile
            ? getMagnitudeLabel(item.alert.expected_percentile)
            : t("unknownPercentile")
        const orderedHazardProfiles: IHazardProfile[] = [];
        const startProfile = item.riskProfile.hazard_profiles.find(profile => profile.logical_op?.toUpperCase() === "START");
        const andProfiles = item.riskProfile.hazard_profiles.filter(profile => profile.logical_op?.toUpperCase() === "AND");

        if (startProfile) {
            orderedHazardProfiles.push(startProfile);
        }

        orderedHazardProfiles.push(...andProfiles);
        const expectedMagnitudeValues =
            item.alert.expected_magnitude_list?.map((magnitude, index) =>
                convertUnits(
                    magnitude,
                    orderedHazardProfiles[index]?.hazard_variable,
                    "convertUnits"
                )
            ) || []
        const magnitudeDescription = expectedMagnitudeValues
            ? getMagnitudeDescription(
                  expectedMagnitudeValues,
                  orderedHazardProfiles,
                  t,
                  getUnit
              )
            : ""

        const hazardProfilesDescription: string[] =
            orderedHazardProfiles.map((profile: any) => {
                const threshold = convertUnits(
                    profile.threshold,
                    profile.hazard_variable,
                    "convertUnits"
                )
                const description = formatConditionDescription(
                    t,
                    item.riskProfile?.type,
                    profile.hazard_variable.readable_name,
                    profile.conditional,
                    profile.window,
                    numFormatter.format(threshold),
                    getUnit(profile.hazard_variable)
                )
                return description
            })

        const informationInAlertCard: InformationInAlertCard = {
            dateRange: {
                icon: <CalendarIcon fill="#666D74" />,
                textLines: [
                    formatDateRange(
                        (["daily", "weekly", "monthly"].includes(
                            item?.alert?.time_resolution
                        )
                            ? item.alert.time_resolution
                            : "daily") as TimeResolutionValues,
                        item.alert.start_date,
                        item.alert.end_date
                    ),
                ],
            },
            magnitude: {
                icon: <MagnitudeIcon fill="#666D74" />,
                textLines: [
                    `${magnitudeLabel}: ${magnitudeDescription}`,
                    `${formatPercentile(
                        item.alert.expected_percentile ?? 0
                    )} ${t("historicalPercentile")}`,
                ],
            },
            probability: {
                icon: <PercentageIcon fill="#666D74" />,
                textLines: [
                    `${probabilityLabel}: ${probabilityPercentage} ${t(
                        "probability"
                    )}`,
                ],
            },
            occurrence: {
                icon: <HistoryIcon fill="#666D74" />,
                textLines: formatOccurrence(
                    t,
                    item.alert.frequency || 0,
                    item.alert.last_occurrence || ""
                ),
            },
            hazardProfileDescription: {
                icon: <DefauktRiskIcon fill="#666D74" />,
                textLines: [
                    formatHazardDescription(hazardProfilesDescription),
                    item.riskProfile.type === "custom"
                        ? t(
                              "basedOnThreshold",
                              "Based on threshold specified by your account Admin"
                          )
                        : "",
                ],
            },            
        }

        item.alertDetailInfo = informationInAlertCard
    })

    return (
        <div className="flex flex-col items-start mx-4 gap-y-2">
            <div className="w-full flex-wrap border-b border-gray-14 py-2 bg-white sticky top-0">
                <div className="flex justify-between">
                    <h1 className="title-sm text-left text-gray-90">
                        {panelContent[0].location.name}
                    </h1>
                    <button
                        onClick={closePanel}
                        className="w-[23px] h-[23px] cursor-pointer">
                        <CancelIcon fill="#666D74" />
                    </button>
                </div>

                <div className={"body-sm text-gray-60 mt-1"}>
                    {locationVarieties.length > 0 ? (
                        <div className="w-full">
                            {locationVarieties.join(", ")}
                        </div>
                    ) : (
                        <div>
                            {t(
                                "noAssetsLinked",
                                "No assets linked to this location"
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div
                className={`w-full flex flex-wrap gap-y-2 ${
                    isPanelOpen ? "block" : "hidden"
                }`}>
                {filteredContent.map((card) => {
                    return (
                        <RiskProfileDetailCard
                            t={t}
                            navigate={navigate}
                            riskProfileInfo={card}
                            riskProfilesVarieties={
                                availableVarietiesPerAlert[
                                    card.riskProfile.id
                                ] || []
                            }
                            alertCategory={
                                selectedCategory as alertCategoriesEnum
                            }
                            enableForecastButton={true}
                            key={`sidepanel_card_${card.alert.id}`}
                            scope={scope}
                            availableVarietiesPerAlert = {availableVarietiesPerAlert}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default SidePanel
