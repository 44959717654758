import {
    BellIcon,
    BlocksIcon,
    CalendarIcon,
    DashboardIcon,
} from "../../climateui/icons"
import { IRoute } from "../../climateui/types"
import dashboardChildRoutes from "./Dashboards/routes"
import DashboardParentView from "./Dashboards/components/DashboardParentView"
import planningToolChildRoutes from "./PlanningTool/routes"
import Alerts from "./Alerts"
import TriggeredAlertsList from "./Alerts/TriggeredAlertsList"
import SchedulerView from "./Scheduler/components/SchedulerView"
import NotFound from "../NotFound"
import PlanningTool from "./PlanningTool/PlanningTool"
import YieldIcon from "../../climateui/icons/variableIcons/YieldIcon"
import YieldOutlookView from "./YieldOutlook"
import RegionalDashboard from "./YieldOutlook/templates/RegionalDashboard"
import LocationDashboard from "./YieldOutlook/templates/LocationDashboard"
import YieldOverview from "./YieldOutlook/components/YieldOverview"
import AlertsLayout from "./Alerts/AlertsLayout"
import AlertPage from "./Alerts/AlertPage"

const seasonalChildRoutes: IRoute[] = [
    {
        path: "alert",
        label: "Alerts",
        labelKey: "alertsLabel",
        accountPermissions: ["monitor_alerts"],
        badgePortalId: "alertsBadge",
        icon: <BellIcon />,
        element: <Alerts />,
        featureFlags: ["ui_seasonal_alerts_page_enabled"],
        children: [{ path: "", element: <TriggeredAlertsList /> }],
    },
    {
        path: "alerts",
        label: "Alerts",
        labelKey: "alertsLabel",
        accountPermissions: ["monitor_alerts_revamp"],
        badgePortalId: "alertBadge",
        icon: <BellIcon />,
        element: <AlertsLayout />,
        children: [{ path: "", element: <AlertPage /> }],
        featureFlags: ["ui_new_alert_page"],
    },
    {
        path: "dashboards",
        label: "Dashboards",
        labelKey: "dashboardsLabel",
        accountPermissions: ["monitor_dashboards"],
        icon: <DashboardIcon />,
        element: <DashboardParentView />,
        // featureFlags: ["ui_seasonal_dashboards_page_enabled"],
        children: dashboardChildRoutes,
        portalId: "dashboardsPortal",
    },
    {
        path: "schedules",
        label: "Scheduler",
        labelKey: "schedulerLabel",
        accountPermissions: ["monitor_schedules"],
        icon: <CalendarIcon />,
        element: <SchedulerView />,
        children: dashboardChildRoutes,
        featureFlags: ["ui_seasonal_schedules_page_enabled"],
    },
    {
        path: "plans",
        label: "Planning Tool",
        labelKey: "planningToolLabel",
        accountPermissions: ["planning_tool"],
        icon: <BlocksIcon />,
        element: <PlanningTool />,
        featureFlags: ["ui_seasonal_plans_page_enabled"],
        children: planningToolChildRoutes,
        portalId: "plansPortal",
    },
    {
        path: "outlook",
        label: "Yield Outlook",
        labelKey: "yieldOutlookLabel",
        accountPermissions: ["monitor_yield_outlook"],
        featureFlags: ["feature_monitor_yield_outlook"],
        icon: <YieldIcon />,
        element: <YieldOutlookView />,
        children: [
            {
                path: "",
                element: <YieldOverview />,
            },
            {
                path: ":assetId",
                labelKey: "assets",
                element: <RegionalDashboard />,
            },
            {
                featureFlags: ["feature_monitor_yield_outlook__locations"],
                path: "locations/:assetId",
                labelKey: "assets",
                element: <LocationDashboard />,
            },
        ],
    },
    {
        path: "*",
        element: <NotFound />,
    },
]

export default seasonalChildRoutes
