import React from "react"

interface InfoSectionProps {
    Icon: React.ReactNode
    textLines: string[]
    key: string
}

const AlertDetailCard: React.FC<InfoSectionProps> = ({
    Icon,
    textLines,
    key,
}) => {
    const textClass = "body-sm leading-4 text-gray-60"
    return (
        <div className="flex w-full items-start">
            <div className={`flex items-start ${textClass}`}>
                <div className="flex justify-center items-start w-[18px] h-[18px] min-w-[18px] min-h-[18px]">
                    {Icon}
                </div>
                <div className="flex flex-col ml-2">
                    {textLines.map((line) => (
                        <p
                            key={key}
                            className={
                                line.includes("threshold") //Highlighting 'Based on assets threshold line'
                                    ? "text-orange mt-1"
                                    : "text-gray-60"
                            }>
                            {line}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AlertDetailCard
