// TODO: Move to yield outlook folder
import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import ShadedRegionsMap from "../../climateui/components/Map/ShadedRegionsMap"
import { IPolygonGroup } from "../../climateui/components/Map/ShadedRegionsMap/types"
import { IYieldStat } from "../../types"
import IWidgetDefaultProps from "../../views/Seasonal/Dashboards/components/widgetLibrary/WidgetDefaultProps"
import { getDirectionOutlook, getIsMeanInMass, getLargestTercile } from "../../utils/transform"
import { useIsFlagEnabled } from "../../hooks"

const STATE_RESOLUTION = 3
interface IYieldOutlookRegion {
    id: string
    geojson: GeoJSON.Geometry
    resolution: number
    name: string
}

interface IYieldOutlookModel {
    region: IYieldOutlookRegion
    stats: {
        results: IYieldStat[]
    }
}

interface IYieldOutlookResponse {
    yield_outlook_model?: { results: IYieldOutlookModel[] }
}

const ShadedRegionMapWidget = (
    props: IWidgetDefaultProps & {
        __data: IYieldOutlookResponse
        loading?: boolean
        toggleNewDirectonalityLogic: boolean
    }
) => {
    const { t } = useTranslate()
    const translatedCategories : Record<number, string> = {
        '-2':t("noSignal", "No signal"),
        '-1':t("belowNormal", "Below normal"),
        '0':t("withinNormal", "Within normal"),
        '1':t("aboveNormal", "Above normal"),
    }

    const isNewDirectionalityToggleEnabled = !!useIsFlagEnabled("experiment_yield_outlook_new_directionality_logic")

    const categorizedRegions = useMemo(() => {
        const results = props.__data.yield_outlook_model?.results
        if (!results || results.length === 0) return {}

        const categories: Record<string, IPolygonGroup> = {

            [translatedCategories[-1]]: {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "#E42437",
                },
            },
            [translatedCategories[0]]: {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "#FDB600",
                },
            },
            [translatedCategories[1]]: {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "#23AF41",
                },
            },
        }
        
        if(isNewDirectionalityToggleEnabled){
            categories[translatedCategories[-2]]= {
                geojson: {
                    type: "FeatureCollection",
                    features: [],
                },
                style: {
                    fillColor: "gray",
                }
             } 
        }
        

        results.forEach((outlook) => {
            // Validate that the information is actually there
            if (!outlook?.region?.geojson || !outlook?.stats?.results?.length)
                return
        
            const region = outlook.region
            const geojson = region.geojson
            const decadal_stat = outlook?.stats?.results[0]
            if (!decadal_stat) return
            const { tercile_probabilities: tercileProbabilities } = decadal_stat

            // let maxVal = 0
            // let category = translatedCategories[1]
            
            
            let directionOutlook = 0

            if(props.toggleNewDirectonalityLogic){
                directionOutlook = getDirectionOutlook(tercileProbabilities)
                if(directionOutlook && directionOutlook !== -2){
                    directionOutlook = getIsMeanInMass(directionOutlook, 
                            decadal_stat.outlook_mean, 
                            decadal_stat.historical
                    )
                }
            } else {
                directionOutlook = getLargestTercile(tercileProbabilities)?.[0] || 0
            }
            
            (
                categories[translatedCategories[directionOutlook]].geojson as { features: GeoJSON.GeoJSON[] }
            ).features.push({
                type: "Feature",
                geometry: geojson,
                properties: {},
            })
        })

        return categories
    }, [props.__data, props.toggleNewDirectonalityLogic])
    return (
        <ShadedRegionsMap
            title={t("directionalYieldOutlook", "Directional Yield Outlook")}
            data={categorizedRegions}
            loading={props.loading}
        />
    )
}
export default ShadedRegionMapWidget
