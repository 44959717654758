import { useState, useContext, useMemo, useEffect } from "react"
import { useTranslate } from "@tolgee/react"
import { MultiSelectFilter } from "../../../../climateui/components"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import { LocationsContext } from "../../../../providers/LocationsProvider"
import {
    IInsightsLocation,
    IRiskProfile,
} from "../../../../types"
import { IBooleanDictionary } from "../../../../climateui/types"
import { DateTime } from "luxon"
import { useSearchParams } from "react-router-dom"
import { useAssets } from "../../../../providers"
import AlertProbabilityFilter from "./AlertProbabilityFilter"

export interface IAlertFilters {
    selectedRegions?: IBooleanDictionary
    selectedLocations?: IBooleanDictionary
    selectedVariables?: IBooleanDictionary
    selectedLabels?: IBooleanDictionary
    selectedProfiles?: IBooleanDictionary
    selectedStartDate?: DateTime
    selectedEndDate?: DateTime
    selectedAssets?: IBooleanDictionary
    minProbability?: number
}
const AlertFilters = ({
    onFilterChange,
}: {
    onFilterChange: (filters: IAlertFilters) => void
}) => {
    const { t } = useTranslate()
    const { riskProfiles: _riskProfiles } = useRiskProfiles()
    const riskProfiles = useMemo(() => _riskProfiles ?? [], [_riskProfiles])
    const { locations } = useContext(LocationsContext)

    const [queryParams, setQueryParams] = useSearchParams()
    const [isInitialLoad, setIsInitialLoad] = useState(true)

    const [selectedRegions, setSelectedRegions] = useState<IBooleanDictionary>(
        {}
    )
    const [selectedLocations, setSelectedLocations] =
        useState<IBooleanDictionary>({})
    const [selectedVariables, setSelectedVariables] =
        useState<IBooleanDictionary>({})
    const [selectedProfiles, setSelectedProfiles] = useState<IBooleanDictionary>(
        {}
    )
    const [selectedLabels, setSelectedLabels] = useState<IBooleanDictionary>({})
    const [selectedAssets, setSelectedAssets] = useState<IBooleanDictionary>({})
    const [minProbability, setMinProbability] = useState<number | undefined>()

    const regionOpts = useMemo(() => {
        const _regions: Record<string, string> = {}
        locations.forEach((location) => {
            if (location.region?.id)
                _regions[location.region.id] = location.region.name
            else if (location.regions)
                location.regions.forEach((region) => {
                    if (region.id) _regions[region.id] = region.name
                })
        })
        return _regions
    }, [locations])

    const filteredRegions = useMemo(() => {
        if (selectedRegions && Object.keys(selectedRegions).length > 0)
            return selectedRegions
        return regionOpts
    }, [selectedRegions, regionOpts])

    const filteredLocations = useMemo(() => {
        const _locations: IInsightsLocation[] = []
        locations.forEach((location) => {
            const regionIDs = Object.keys(filteredRegions)
            if (location.regions) {
                if (
                    location.regions.some((region) => {
                        if (region.id) return regionIDs.includes(region.id)
                    })
                )
                    _locations.push(location)
            } else if (location.region) {
                if (
                    location.region.id &&
                    regionIDs.includes(location.region.id)
                )
                    _locations.push(location)
            }
        })
        return _locations
    }, [filteredRegions])

    const locationOpts = useMemo(
        () =>
            filteredLocations.reduce(
                (
                    prev: Record<string, string>,
                    curr: IInsightsLocation
                ) => {
                    return { ...prev, [curr.id as string]: curr.name }
                },
                {}
            ),
        [filteredLocations]
    )

    const riskProfileOpts = useMemo(
        () =>
            riskProfiles
                // Sort alphabetically by risk profile name
                .sort((a, b) =>
                    a.name === b.name ? 0 : a.name > b.name ? 1 : -1
                )
                .reduce(
                    (
                        prev: Record<string, string>,
                        curr: IRiskProfile
                    ) => ({
                        ...prev,
                        [curr.id]: curr.name,
                    }),
                    {}
                ),
        [riskProfiles]
    )
    const hazardVariableOpts = useMemo(() => {
        const options: Record<string, string> = {}
        riskProfiles.forEach((riskProfile) => {
            riskProfile.hazard_profiles.forEach((hazardProfile) => {
                const hazardVariable = hazardProfile.hazard_variable
                if (!options[hazardVariable.id]) {
                    options[hazardVariable.id] = hazardVariable.readable_name
                }
            })
        })
        return options
    }, [riskProfiles])

    const labelsOpts = useMemo(() => {
        const options: Record<string, string> = {}
        riskProfiles.forEach((riskProfile) => {
            riskProfile.labels.forEach((label) => {
                if (label.id && !options[label.id]) {
                    options[label.id] = label.name
                }
            })
        })
        return options
    }, [riskProfiles])

    const { varietiesOptions } = useAssets()

    const arrToFilterVal = (_arr: string | string[]) => {
        const arr = typeof _arr === "string" ? [_arr] : _arr
        return arr.reduce(
            (prev: Record<string, boolean>, curr: string) => ({
                ...prev,
                [curr]: true,
            }),
            {}
        )
    }
    const parseQueryParam = (param: string) => JSON.parse(decodeURI(param))
    useEffect(() => {
        if (!isInitialLoad) return
        const region = queryParams.get("region")
        const location = queryParams.get("location")
        const risk = queryParams.get("risk")
        const variable = queryParams.get("variable")
        const label = queryParams.get("label")
        const asset = queryParams.get("asset")
        const minProb = queryParams.get("min_prob")
        if (region) setSelectedRegions(arrToFilterVal(parseQueryParam(region)))
        if (location)
            setSelectedLocations(arrToFilterVal(parseQueryParam(location)))
        if (risk) setSelectedProfiles(arrToFilterVal(parseQueryParam(risk)))
        if (variable)
            setSelectedVariables(arrToFilterVal(parseQueryParam(variable)))
        if (label) setSelectedLabels(arrToFilterVal(parseQueryParam(label)))
        if (asset) setSelectedAssets(arrToFilterVal(parseQueryParam(asset)))
        if (minProb) setMinProbability(parseQueryParam(minProb))
        setIsInitialLoad(false)
    }, [queryParams])

    const serializeFilters = ({
        selectedRegions,
        selectedLocations,
        selectedVariables,
        selectedLabels,
        selectedProfiles,
        selectedStartDate,
        selectedEndDate,
        selectedAssets,
        minProbability,
    }: IAlertFilters) => {
        const _queryParams: Record<string, string | string[]> = {}
        if (selectedRegions)
            _queryParams["region"] = JSON.stringify(
                Object.keys(selectedRegions)
            )
        if (selectedLocations)
            _queryParams["location"] = JSON.stringify(
                Object.keys(selectedLocations)
            )
        if (selectedProfiles)
            _queryParams["risk"] = JSON.stringify(Object.keys(selectedProfiles))
        if (selectedVariables)
            _queryParams["variable"] = JSON.stringify(
                Object.keys(selectedVariables)
            )
        if (selectedLabels)
            _queryParams["label"] = JSON.stringify(Object.keys(selectedLabels))
        if (selectedStartDate) _queryParams["start"] = selectedStartDate.toISO()
        if (selectedEndDate) _queryParams["end"] = selectedEndDate.toISO()
        if (selectedAssets)
            _queryParams["asset"] = JSON.stringify(Object.keys(selectedAssets))
        if (minProbability)
            _queryParams["min_prob"] = JSON.stringify(minProbability)
        return _queryParams
    }
    useEffect(() => {
        // Skips the filters that have not being applied
        const filterStates = {
            selectedRegions,
            selectedLocations,
            selectedVariables,
            selectedProfiles,
            selectedLabels,
            selectedAssets,
        }
        let filters: IAlertFilters = {
            minProbability,
        }
        Object.entries(filterStates).forEach(([key, state]) => {
            if (Object.keys(state).length) {
                filters = {
                    ...filters,
                    [key]: state,
                }
            }
        })
        onFilterChange(filters)
        // Create candidate for query params
        const _queryParams = {
            ...serializeFilters(filters),
        }
        // Extract possible query params dates
        const _start = queryParams.get("start")
        const _end = queryParams.get("end")
        // If the start date query param exists, include it
        if (_start) {
            _queryParams["start"] = _start
        }
        // If the end date query param exists, include it
        if (_end) {
            _queryParams["end"] = _end
        }
        // Set the new query params
        setQueryParams(_queryParams)
    }, [
        selectedRegions,
        selectedLocations,
        selectedVariables,
        selectedProfiles,
        selectedLabels,
        selectedAssets,
        minProbability,
    ])

    return (
        <div className="flex flex-wrap mb-3 gap-1">
            <MultiSelectFilter
                filterName={t("region")}
                showSelectedOnTop={true}
                options={regionOpts}
                selected={selectedRegions}
                setSelected={setSelectedRegions}
                placeholder={t("region", "Region")}
                searchPlaceholder={t("search")}
                leftRightClass="left-0"
                canSearch={true}
                sortByKey={false}
            />
            <MultiSelectFilter
                filterName={t("location")}
                showSelectedOnTop={true}
                options={locationOpts}
                selected={selectedLocations}
                sortByKey={false}
                setSelected={setSelectedLocations}
                placeholder={t("location", "Location")}
                searchPlaceholder={t("search")}
                leftRightClass="left-0"
                canSearch={true}
            />
            <MultiSelectFilter
                filterName={t("risk")}
                showSelectedOnTop={true}
                options={riskProfileOpts}
                selected={selectedProfiles}
                setSelected={setSelectedProfiles}
                placeholder={t("risk", "Risk")}
                searchPlaceholder={t("search")}
                leftRightClass="left-0"
                canSearch={true}
                sortByKey={false}
            />
            <MultiSelectFilter
                filterName={t("variable")}
                showSelectedOnTop={true}
                options={hazardVariableOpts}
                sortByKey={false}
                selected={selectedVariables}
                setSelected={setSelectedVariables}
                placeholder={t("variable", "Variable")}
                searchPlaceholder={t("search")}
                leftRightClass="left-0"
                canSearch={true}
            />
            <MultiSelectFilter
                filterName={t("label")}
                showSelectedOnTop={true}
                options={labelsOpts}
                selected={selectedLabels}
                setSelected={setSelectedLabels}
                sortByKey={false}
                placeholder={t("label", "Label")}
                searchPlaceholder={t("search")}
                leftRightClass="left-0"
                canSearch={true}
            />
            <MultiSelectFilter
                filterName={t("asset")}
                showSelectedOnTop={true}
                options={varietiesOptions}
                selected={selectedAssets}
                sortByKey={false}
                setSelected={setSelectedAssets}
                placeholder={t("asset", "Asset")}
                searchPlaceholder={t("search")}
                leftRightClass="left-0"
                canSearch={true}
            />
            <AlertProbabilityFilter
                probability={minProbability}
                setProbability={setMinProbability}
            />
        </div>
    )
}

export default AlertFilters
