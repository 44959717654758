//import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import {
    NavigateFunction,
    useNavigate,
    useOutletContext,
    useSearchParams,
} from "react-router-dom"
import { IFullMapContext } from "./AlertsLayout"
import { isValidResponse } from "../../../climateui/utils/http"
import { useMemoQuery } from "../../../hooks"
import { useAccount } from "../../../providers/AccountProvider"
import {
    ITrigger,
    IRiskProfile,
    IInsightsLocation,
    IVariable,
    AlertDetailItem,
    IVariety,
    ILocationEssentials,
} from "../../../types"
import { IFilter } from "../../../utils/filters"
import {
    alertQuerySet,
    locationListSearchGET,
    riskProfileQuerySet,
    triggeredAlertsPOST,
} from "../../../utils/networking"
import { arrToDict } from "../../../utils/transform"
import AlertPageFilters from "./components/Filters/AlertPageFilters"
import { DateTime } from "luxon"
import { useAlerts, useAssets, useLocale } from "../../../providers"
import {
    getFirstRelevantDate,
    getLastRelevantDate,
    matchesSelectedAssets,
    matchesSelectedRegions,
} from "./utils"
import { IMapPin } from "../../../climateui/components/Map/utils"
import { useAlertsMarkerPopup } from "./components/mapUtils"
import DASHBOARD_VARIABLES from "../Dashboards/dashboardVariables"
import CategorySelector from "./components/Filters/WarningWatchSelector"
import { IAlertFilters } from "./components/AlertFilters"
import AlertDateNavigator from "./components/AlertDateNavigator"
import { IGroupedAlerts } from "../../../../src/types"
import AlertsCard from "./components/AlertCard"
import SidePanel from "./components/SidePanel"
import { NOON } from "../../../utils/dates"
import { isEmpty } from "lodash"

type AlertsGroupedByLocation = Record<string, ITrigger[]>

const alertCategoriesStyle: Record<
    string,
    {
        originalState: string
        highlightState: string
        fillColor: string
        pinStyle: string
        normalTextColor: string
        selectedTextColor: string
    }
> = {
    warning: {
        originalState: "url('/images/map-alert-pin.png')",
        highlightState: "url('/images/map-alert-pin-selected.png')",
        fillColor: "#E42437",
        pinStyle: "map-alert-pin.png",
        normalTextColor: "#FFFFFF",
        selectedTextColor: "#E42437",
    },
    watch: {
        originalState: "url('/images/map_alert_watch_pin.png')",
        highlightState: "url('/images/map-watch-alert-pin-selected.png')",
        fillColor: "#FDB600",
        pinStyle: "map_alert_watch_pin.png",
        normalTextColor: "#000000",
        selectedTextColor: "#CC9300",
    },
}

const scopeStrings = {
    0: "daily",
    1: "weekly",
    2: "monthly",
}

function resetMarkerStyle(marker: mapboxgl.Marker) {
    const markerElement = marker.getElement()
    const originalStyle =
        markerElement.getAttribute("data-original-style") ??
        "url('/images/map-alert-pin.png')"
    markerElement.style.backgroundImage = originalStyle

    const textElement = markerElement.querySelector(".label-sm")
    if (textElement) {
        const originalTextColor =
            textElement.getAttribute("data-original-text-color") ?? "text-white"
        if (originalTextColor === "text-black") {
            textElement.classList.replace("text-[#CC9300]", "text-black")
        } else {
            textElement.classList.replace("text-red", "text-white")
            textElement.classList.replace("text-black", "text-white")
        }
    }
}

function handleClickEvent(
    selectedMarkerRef: React.MutableRefObject<mapboxgl.Marker | null>,
    marker: mapboxgl.Marker,
    selectedCategory: string
) {
    if (selectedMarkerRef.current && selectedMarkerRef.current !== marker) {
        resetMarkerStyle(selectedMarkerRef.current)
    }

    if (selectedMarkerRef.current !== marker) {
        const newElement = marker.getElement()
        newElement.setAttribute(
            "data-original-style",
            newElement.style.backgroundImage
        )
        const highlightState =
            alertCategoriesStyle[selectedCategory]?.highlightState ||
            alertCategoriesStyle["default"].highlightState
        newElement.style.backgroundImage = highlightState

        const newTextElement = newElement.querySelector(
            ".label-sm"
        ) as HTMLElement

        if (newTextElement) {
            newTextElement.setAttribute(
                "data-original-text-color",
                newTextElement.classList.contains("text-black")
                    ? "text-black"
                    : "text-white"
            )
            let newTextColor: string
            if (selectedCategory === "watch") {
                newTextColor = "text-[#CC9300]"
            } else {
                newTextColor = "text-red"
            }
            if (
                newTextElement.classList.contains("text-black") ||
                newTextElement.classList.contains("text-white")
            ) {
                newTextElement.classList.replace("text-black", newTextColor)
                newTextElement.classList.replace("text-white", newTextColor)
            }
        }
        selectedMarkerRef.current = marker
    }
}

function createSimpleMarkerEvents(marker: mapboxgl.Marker) {
    return {
        mouseenter: () => marker.togglePopup(),
        mouseleave: () => marker.togglePopup(),
    }
}

function createMarkerEvents(
    marker: mapboxgl.Marker,
    selectedMarkerRef: React.MutableRefObject<mapboxgl.Marker | null>,
    pinData: any,
    updatePanelContent: any,
    selectedCategory: string
) {
    return {
        click: () => {
            handleClickEvent(selectedMarkerRef, marker, selectedCategory)
            updatePanelContent(pinData)
        },
        ...createSimpleMarkerEvents(marker),
    }
}

function genericMarkerEvent(
    marker: mapboxgl.Marker,
    location: any,
    navigate: NavigateFunction,
    scope: number
) {
    const scopeString =
        scopeStrings[scope as keyof typeof scopeStrings] || "daily"
    return {
        click: () => {
            navigate(
                "/seasonal/dashboards/location?&locId=" +
                    location.id +
                    "&scope=" +
                    scopeString
            )
        },
        ...createSimpleMarkerEvents(marker),
    }
}

const AlertPage = () => {
    /* CONSTS > START */
    const [selectedCategory, setSelectedCategory] = useState<string>("warning")
    const [alertCount, setAlertCount] = useState<number | undefined>(undefined)
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [panelContent, setPanelContent] = useState<AlertDetailItem[] | null>(
        null
    )
    const [scope, setScope] = useState<number>(0)

    /* CONSTS < END */
    /* HOOKS > START */
    const { selectedAccount, prevSelectedAccount } = useAccount()
    //const { childLocations } = useContext(LocationsContext)
    const { setMapPins } = useOutletContext<IFullMapContext>()

    const [firstAlertDate, setFirstAlertDate] = useState<DateTime>()
    const [lastAlertDate, setLastAlertDate] = useState<DateTime>()
    const variables: Record<string, IVariable> = DASHBOARD_VARIABLES

    const variablesDict = {} as Record<string, string>
    Object.values(variables).forEach((v) => (variablesDict[v.value] = v.name))

    const { getAlertsMarkerPopup } = useAlertsMarkerPopup()

    const { alertSettingsIDs } = useAlerts()
    const { language } = useLocale()
    const [, setQueryParams] = useSearchParams()
    const navigate = useNavigate()
    const { varieties } = useAssets()
    const riskProfileVarietiesMap: Record<string, string[]> = {}

    /* HOOKS < END */

    /* STATE > START */
    const defaultFilters = {
        selectedStartDate: DateTime.now().set(NOON),
        selectedEndDate: DateTime.now().set(NOON).plus({ days: 14 }),
    }
    const [filters, setFilters] = useState<IAlertFilters>(defaultFilters)

    /* STATE < END */

    /* STATE > MEMO > START */
    const riskProfilesFilterPayload = useMemo(() => {
        const defaultFilter: IFilter = {
            filter_by: {
                and: [],
            },
        }
        if (filters.selectedVariables) {
            defaultFilter.filter_by.and?.push({
                field_name: "risk_profile.RiskProfile.hazard_profiles",
                operator: "any",
                field_value: {
                    field_name:
                        "hazard_profile.HazardProfile.hazard_variable_id",
                    operator: "in",
                    field_value: Object.keys(filters.selectedVariables),
                },
            })
        }
        return defaultFilter
    }, [filters.selectedAssets, filters.selectedVariables, selectedAccount])

    const [riskProfiles] = useMemoQuery<Record<string, IRiskProfile>>(
        ["alertsRiskProfiles", riskProfilesFilterPayload, selectedAccount],
        () =>
            riskProfileQuerySet.post("/search", {
                ...riskProfilesFilterPayload,
                pagination: {
                    all: true,
                },
            }),
        {
            enabled: !!riskProfilesFilterPayload,
        },
        (data) =>
            arrToDict((data as { data: IRiskProfile[] }).data, "id") ?? {},
        {}
    )
    const locationsFilterPayload = useMemo(() => {
        const defaultFilter: IFilter = {
            filter_by: {
                and: [
                    {
                        field_name: "location.Location.account_id",
                        operator: "eq",
                        field_value: selectedAccount,
                    },
                ],
            },
        }
        if (filters.selectedLocations) {
            defaultFilter.filter_by.and?.push({
                field_name: "location.Location.id",
                operator: "in",
                field_value: JSON.stringify(
                    Object.keys(filters.selectedLocations)
                ),
            })
        }
        return defaultFilter
    }, [selectedAccount])

    const [locations, queryResponse] = useMemoQuery<
        Record<string, ILocationEssentials>
    >(
        [
            "alertsLocations",
            locationsFilterPayload,
            filters.selectedRegions,
            filters.selectedAssets,
        ],
        () =>
            locationListSearchGET({
                ...locationsFilterPayload,
                pagination: {
                    all: true,
                },
            }),
        {
            enabled: !!locationsFilterPayload,
        },
        (data) => {
            const initialData = (data as { data: IInsightsLocation[] }).data
            const selectedRegions = filters.selectedRegions
                ? Object.keys(filters.selectedRegions)
                : []
            const selectedAssets = filters.selectedAssets
                ? Object.keys(filters.selectedAssets)
                : []

            let filteredLocations = initialData
            if (selectedRegions.length > 0 || selectedAssets.length > 0) {
                filteredLocations = initialData.filter((location) => {
                    const regionMatch =
                        selectedRegions.length === 0 ||
                        matchesSelectedRegions(location, selectedRegions)
                    const assetMatch =
                        selectedAssets.length === 0 ||
                        matchesSelectedAssets(
                            location.varieties,
                            selectedAssets
                        )
                    return regionMatch && assetMatch
                })
            }
            if (filteredLocations.length === 0) setAlertCount(0)

            return {
                ...arrToDict(filteredLocations, "id"),
            }
        },
        {}
    )

    const canFetchAlerts = useMemo(
        () =>
            Object.keys(riskProfiles ?? {}).length > 0 &&
            Object.keys(locations ?? {}).length > 0 &&
            (alertSettingsIDs ?? []).length > 0 &&
            !!selectedAccount,
        [selectedAccount, locations, riskProfiles, alertSettingsIDs]
    )
    /* STATE > MEMO < END */

    /* NETWORK > START */
    const alertsFilterPayload = useMemo(() => {
        const defaultFilter: IFilter = {
            filter_by: {
                and: [
                    {
                        field_name: "processing_run.ProcessingRun.account_id",
                        operator: "eq",
                        field_value: selectedAccount,
                    },
                    {
                        field_name:
                            "processing_run.ProcessingRun.risk_setting_id",
                        operator: "in",
                        field_value: JSON.stringify(alertSettingsIDs),
                    },
                    {
                        field_name: "alert.Alert.category",
                        operator: "ne",
                        field_value: "No Action",
                    },
                ],
            },
        }
        if (filters.selectedStartDate && filters.selectedEndDate) {
            defaultFilter.filter_by.and?.push({
                field_name: "alert.Alert.start_date",
                operator: "lte",
                field_value: filters.selectedEndDate.toUTC().toISO(),
            })
            defaultFilter.filter_by.and?.push({
                field_name: "alert.Alert.end_date",
                operator: "gte",
                field_value: filters.selectedStartDate.toUTC().toISO(),
            })
        }
        if (riskProfiles) {
            defaultFilter.filter_by.and?.push({
                field_name: "processing_run.ProcessingRun.risk_id",
                operator: "in",
                field_value: Object.keys(riskProfiles),
            })
        }
        return defaultFilter
    }, [selectedAccount, filters, alertSettingsIDs, riskProfiles])

    const {
        data: alertsResponse,
        isLoading,
        isRefetching,
    } = useQuery({
        queryKey: ["alerts", canFetchAlerts, alertsFilterPayload, language],
        queryFn: () => {
            return triggeredAlertsPOST({
                ...alertsFilterPayload,
                order_by: ["-processing_run.ProcessingRun.ran_at"],
                pagination: {
                    all: true,
                },
            })
        },
        enabled: !!canFetchAlerts,
    })
    const { data: alertsDates } = useQuery({
        queryKey: ["alertsDates", selectedAccount],
        queryFn: () =>
            alertQuerySet.post({
                path: "/search",
                config: {
                    headers: {
                        "X-Fields": "{data{alerts{start_date,end_date}}}",
                    },
                },
            }),
        select: (response) => {
            if (!isValidResponse(response)) return []
            const groupedAlerts = response.data.data as {
                alerts: {
                    start_date: string
                    end_date: string
                }[]
            }[]
            return groupedAlerts.map(({ alerts }) => alerts).flat()
        },
    })

    const [alerts, setAlerts] = useState<IGroupedAlerts[]>([])
    const _mapPinsGeneric: Record<string, IMapPin> = {}
    const locationPins = () => {
        const selectedAssets = Object.keys(filters.selectedAssets ?? {}).filter(
            (key) => filters.selectedAssets?.[key]
        )
        const hasActiveFilters = selectedAssets.length > 0

        const locationPin = hasActiveFilters
            ? Object.values(locations).filter((location) =>
                  location.varieties.some((varietyId) => {
                      if (typeof varietyId === "string")
                          return selectedAssets.includes(varietyId)
                  })
              )
            : Object.values(locations)

        locationPin.forEach((location) => {
            const key = `${location.longitude},${location.latitude}`
            const pin = _mapPinsGeneric[key] || {
                lon: location.longitude,
                lat: location.latitude,
                id: location.id,
                data: {
                    location,
                    riskProfiles: {},
                },
                events: (marker) =>
                    genericMarkerEvent(marker, location, navigate, scope),
                getCount: () => {
                    return 0
                },
                popup: (pin) => getAlertsMarkerPopup(pin),
            }
            _mapPinsGeneric[key] = pin
        })
        return _mapPinsGeneric
    }

    useEffect(() => {
        if (!isValidResponse(alertsResponse)) return
        const alertsByCategory = alertsResponse.data.data.filter(
            (item: { alert: { category: string } }) =>
                item.alert.category.toLowerCase() === selectedCategory
        )

        const _alerts: Record<string, IGroupedAlerts> = {}
        alertsByCategory.forEach((trigger: ITrigger) => {
            const location = locations[trigger.processing_run.location_id]
            const riskProfile = riskProfiles[trigger.processing_run.risk_id]
            if (!location || !riskProfile) return

            const groupKey = `${riskProfile.id}-${location.id}`
            if (_alerts[groupKey]) {
                _alerts[groupKey].alerts.push(trigger)
            } else {
                _alerts[groupKey] = {
                    riskProfile,
                    location,
                    alerts: [trigger],
                }
            }
        })

        setAlertCount(alertsByCategory.length)
    }, [alertsResponse, selectedCategory, riskProfiles, locations, filters])

    // Set the alerts dates
    useEffect(() => {
        if (!alertsDates) {
            setFirstAlertDate(undefined)
            setLastAlertDate(undefined)
        } else {
            setFirstAlertDate(getFirstRelevantDate(alertsDates))
            setLastAlertDate(getLastRelevantDate(alertsDates))
        }
    }, [alertsDates])

    // Clear params // why??
    // doesn't make sense lol
    useEffect(() => {
        if (!selectedAccount || !prevSelectedAccount) return
        if (selectedAccount !== prevSelectedAccount) setQueryParams()
    }, [selectedAccount, prevSelectedAccount])

    const selectedMarkerRef = useRef<mapboxgl.Marker | null>(null)

    const closePanel = () => {
        setIsPanelOpen(false)
    }

    const handleUpdatePanelContent = (content: any) => {
        setPanelContent(content)
        setIsPanelOpen(true)
    }

    useEffect(() => {
        if (isLoading || !queryResponse.isFetched || isEmpty(riskProfiles)) {
            setAlertCount(undefined)
        }
    }, [riskProfiles, queryResponse, isLoading])

    useEffect(() => {
        if (!isValidResponse(alertsResponse)) {
            locationPins()
            setMapPins(Object.values(_mapPinsGeneric))
            return
        }

        const { data: _data } = alertsResponse
        const data = _data.data as ITrigger[]
        const _mapPins: Record<string, IMapPin> = {}
        const _alerts: Record<string, IGroupedAlerts> = {}

        const assetIds = Object.keys(filters.selectedAssets || {})
        const relevantLocations = Object.values(locations).filter((location) =>
            location.varieties.some((variety) =>
                typeof variety === "string"
                    ? assetIds.includes(variety)
                    : assetIds.includes(variety.id)
            )
        )
        let intermediateData = data

        if (relevantLocations.length > 0) {
            const relevantLocationIds = new Set(
                relevantLocations.map((location) => location.id)
            )
            intermediateData = data.filter((item) =>
                relevantLocationIds.has(item.processing_run.location_id)
            )
        }

        const filteredDataByCategory = selectedCategory
            ? intermediateData.filter(
                  (item) =>
                      item.alert.category.toLowerCase() ===
                      selectedCategory.toLowerCase()
              )
            : intermediateData

        const alertsGroupedByLocation =
            filteredDataByCategory.reduce<AlertsGroupedByLocation>(
                (acc, item) => {
                    const locationId = item.processing_run.location_id
                    if (!acc[locationId]) {
                        acc[locationId] = []
                    }
                    acc[locationId].push(item)
                    return acc
                },
                {}
            )

        const pinStyle =
            selectedCategory && alertCategoriesStyle[selectedCategory].pinStyle

        filteredDataByCategory.forEach((trigger) => {
            const location = locations[trigger.processing_run.location_id]
            const alertsArray =
                alertsGroupedByLocation[trigger.processing_run.location_id]
            const riskProfile = riskProfiles[trigger.processing_run.risk_id]
            if (!location || !riskProfile) return

            const triggersForLocation =
                alertsGroupedByLocation[location.id as string]
            const pinData = triggersForLocation?.map((trigger) => ({
                location: location,
                riskProfile: riskProfiles[trigger.processing_run.risk_id],
                alert: trigger.alert,
            }))

            const key = `${location.longitude},${location.latitude}`
            const pin = _mapPins[key] || {
                lon: location.longitude,
                lat: location.latitude,
                id: location.id,
                pinStyle: pinStyle,
                category: selectedCategory,
                data: {
                    location,
                    riskProfiles: {},
                    alerts: alertsArray.map(
                        (trigger: ITrigger) => trigger.alert
                    ),
                    category: selectedCategory,
                },
                events: (marker: mapboxgl.Marker) =>
                    createMarkerEvents(
                        marker,
                        selectedMarkerRef,
                        pinData,
                        handleUpdatePanelContent,
                        selectedCategory
                    ),
                getCount: (pin) => Object.keys(pin.data.alerts).length,
                popup: getAlertsMarkerPopup,
            }

            pin.data.riskProfiles[riskProfile.id] = riskProfile
            _mapPins[key] = pin
        })

        const genericMapPins = locationPins()
        const _mapPinsValsGeneric = Object.values(genericMapPins)

        const _mapPinsVals = Object.values(_mapPins)
        const _mapPinsIds = new Set(_mapPinsVals.map((pin) => pin.id))

        const filteredMapPinsValsGeneric = _mapPinsValsGeneric.filter(
            (pin) => !_mapPinsIds.has(pin.id)
        )
        const alertPins = [...filteredMapPinsValsGeneric, ..._mapPinsVals]

        const totalAlerts = Object.values(alertsGroupedByLocation).reduce(
            (total, alerts) => {
                return total + alerts.length
            },
            0
        )

        const totalAlertsInPins = _mapPinsVals.reduce((total, pin) => {
            return total + (pin.data.alerts ? pin.data.alerts.length : 0)
        }, 0)

        setMapPins(Object.values(alertPins))
        setAlerts(Object.values(_alerts))
        if (filters.selectedRegions) return setAlertCount(totalAlertsInPins)
        if (filters.selectedAssets) return setAlertCount(totalAlerts)
    }, [alertsResponse, selectedCategory, locations, filters, riskProfiles])

    useEffect(() => {
        setIsPanelOpen(false)
    }, [selectedCategory, filters, selectedAccount])

    useEffect(() => {
        if (!isPanelOpen && selectedMarkerRef.current) {
            resetMarkerStyle(selectedMarkerRef.current)
            selectedMarkerRef.current = null
        }
    }, [isPanelOpen])

    useEffect(() => {
        locationPins()
    }, [scope])

    const getRiskProfilesVarieties = (variety: string | IVariety) => {
        if (typeof variety === "string") {
            const varietyDetail = varieties?.[variety]
            if (varietyDetail) {
                return `${varietyDetail.asset.name} (${varietyDetail.name})`
            }
        } else if (variety.id) {
            return `${variety.asset.name} (${variety.name})`
        }
        return ""
    }

    panelContent?.forEach(({ riskProfile }: { riskProfile: IRiskProfile }) => {
        if (!riskProfile) return
        const varietiesInRiskProfile: string[] = riskProfile?.varieties?.map(
            (variety: string | IVariety) => getRiskProfilesVarieties(variety)
        )
        riskProfileVarietiesMap[riskProfile.id] = varietiesInRiskProfile
    })

    return (
        <div className="h-[32px]">
            <div className="relative h-[32px] w-full">
                {/* Loader */}
                {(isLoading || isRefetching) && (
                    <div className="absolute inset-0 flex justify-center items-center loading-screen__loader z-50" />
                )}
                <div className="absolute top-0 left-0 right-0 px-4 py-4 flex items-center z-20">
                    <div className="flex flex-row space-x-2">
                        <CategorySelector
                            selectedCategory={selectedCategory}
                            handleSelectCategory={setSelectedCategory}
                        />
                        <AlertDateNavigator
                            onChange={(
                                selectedStartDate: DateTime,
                                selectedEndDate: DateTime
                            ) => {
                                setFilters({
                                    ...filters,
                                    selectedStartDate,
                                    selectedEndDate,
                                })
                            }}
                            isLoading={isLoading}
                            initialDate={firstAlertDate}
                            defaultMode={0}
                            minDate={firstAlertDate}
                            maxDate={lastAlertDate}
                            setScope={setScope}
                        />
                        <AlertPageFilters
                            onFilterChange={(newFilters) => {
                                setFilters({
                                    ...newFilters,
                                    selectedStartDate:
                                        filters.selectedStartDate,
                                    selectedEndDate: filters.selectedEndDate,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="absolute bottom-8 left-27 z-50">
                {selectedCategory && (
                    <AlertsCard
                        count={alertCount}
                        alertType={selectedCategory}
                        fillColor={
                            alertCategoriesStyle[selectedCategory].fillColor
                        }
                    />
                )}
            </div>
            <div
                className={`absolute top-0 right-0 bottom-0 w-[360px] bg-white overflow-y-scroll transition-transform ${
                    isPanelOpen ? "translate-x-0" : "translate-x-full"
                } z-30 h-full`}>
                {panelContent && (
                    <SidePanel
                        isPanelOpen={isPanelOpen}
                        panelContent={panelContent}
                        availableVarietiesPerAlert={riskProfileVarietiesMap}
                        closePanel={closePanel}
                        selectedCategory={selectedCategory}
                        scope={scope}
                    />
                )}
            </div>
        </div>
    )
}
export default AlertPage
