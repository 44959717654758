import { LngLatBounds } from "mapbox-gl"
import { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { IMapPin } from "../../../climateui/components/Map/utils"
import { HalfMapLayout } from "../../../layouts"

export interface IMapContext {
    mapPins: IMapPin[]
    setMapPins: (pins: IMapPin[]) => void
    boundingBox?: LngLatBounds
}

function AlertsLayout() {
    const location = useLocation()

    const [mapPins, setMapPins] = useState<IMapPin[]>([])
    const [boundingBox, setBoundingBox] = useState<LngLatBounds>()

    useEffect(() => {
        setTimeout(() => {
            const _event = new CustomEvent("force-resize", { bubbles: true })
            window.dispatchEvent(_event)
        }, 100)
    }, [location.pathname])

    return (
        <HalfMapLayout
            contentWidthClasses="w-full lg:w-[50%] xl:w-[61.80%] 2xl:w-[50%]"
            mapProps={{
                pins: mapPins,
                boundingBoxCallback: setBoundingBox,
                mapConfigs: {
                    minZoom: 1.1,
                    maxZoom: 15,
                    renderWorldCopies: false,
                },
            }}>
            <Outlet context={{ mapPins, setMapPins, boundingBox }} />
        </HalfMapLayout>
    )
}

export default AlertsLayout
