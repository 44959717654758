import { useMemo } from "react"
import { useTranslate } from "@tolgee/react"
import TableWidget, {
    ITableWidgetColumn,
    ITableWidgetProps,
} from "../../../../../climateui/components/Widgets/Table/TableWidget"
import { IVariety } from "../../../../../types"
import { formatConditionDescription } from "../../../Alerts/utils"

const numFormatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 1,
})

function AlertSummaryTableWidget(props: ITableWidgetProps) {
    const {
        data,
        columns,
        title,
        loading,
        empty,
        error,
        reload,
        isPaginated = true,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
    } = props
    const { t } = useTranslate()

    const _data: any[] = []
    data?.forEach((result) => {
        const alertRiskProfile = result.processing_run.risk_profile
        if (!alertRiskProfile) return
        const varietiesLinkedToAlerts =
            alertRiskProfile.varieties?.results.map(
                (variety: IVariety) => `${variety.asset.name} (${variety.name})`
            ) || []
        const hazardProfilesDescription =
            alertRiskProfile.hazard_profiles?.results
                .map((profile: any) => {
                    if (!profile) return
                    const description = formatConditionDescription(
                        t,
                        alertRiskProfile?.type,
                        profile.hazard_variable.readable_name,
                        profile.conditional,
                        profile.window,
                        numFormatter.format(profile.threshold),
                        profile.hazard_variable.units
                    )
                    return description
                })
                .join(", ")
        const hazardProfilesIcon =
            alertRiskProfile.hazard_profiles?.results.length > 1
                ? "stacked"
                : alertRiskProfile.hazard_profiles?.results[0].hazard_variable
                      .dashboard_variable

        _data.push({
            ...result,
            hazard_profiles_description: {
                icon: hazardProfilesIcon,
                description: hazardProfilesDescription,
            },
            variety_description:
                varietiesLinkedToAlerts.length > 0
                    ? varietiesLinkedToAlerts.join(", ")
                    : t("locationInsights", "Location Insights"),
        })
    })

    const _columns = useMemo(() => {
        if (!columns) return []
        const hazardProfilesColumn: ITableWidgetColumn = {
            propName: "variable",
            header: t("variable"),
            type: "text",
            selector: {
                icon: `{{ hazard_profiles_description.icon }}`,
                text: `{{ hazard_profiles_description.description }}`,
                delimeter: ",",
            },
        }
        const varietiesColumn: ITableWidgetColumn = {
            propName: "assets",
            header: t("assets"),
            type: "text",
            selector: {
                text: `{{ variety_description }}`,
            },
        }
        const locationColumn = columns.filter((column) =>
            column.propName.includes("location")
        )
        const riskProfileColumn = columns.filter((column) =>
            column.propName.includes("risk")
        )
        const periodColumn = columns.filter((column) =>
            column.propName.includes("period")
        )

        return [
            ...locationColumn,
            varietiesColumn,
            ...riskProfileColumn,
            hazardProfilesColumn,
            ...periodColumn,
        ]
    }, [data, columns])

    return (
        <TableWidget
            data={_data}
            columns={_columns}
            loading={loading}
            title={title}
            error={error}
            empty={empty}
            reload={reload}
            isPaginated={isPaginated}
            errorMessage={errorMessage}
            reloadMessage={reloadMessage}
            noResultsMessage={noResultsMessage}
            tryAnotherFilterMessage={tryAnotherFilterMessage}
        />
    )
}

export default AlertSummaryTableWidget
