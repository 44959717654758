import { createContext, ReactNode, useContext } from "react"
import { useAuth } from "./AuthProvider"
import { IHazardVariable } from "../types"
import configureMeasurements, {
    allMeasures,
    AllMeasures,
    AllMeasuresSystems,
    AllMeasuresUnits,
} from "convert-units"

export interface IUnitsConfig {
    units_imperial: string
    units_metric: string
}

const convert = configureMeasurements<
    AllMeasures,
    AllMeasuresSystems,
    AllMeasuresUnits
>(allMeasures)
export interface IUnitConversion {
    convertUnits: (
        value: number,
        variable: IUnitsConfig,
        direction: "convertUnits" | "resetUnits"
    ) => number
    getUnit: (variable: IHazardVariable) => string
    unitName: "metric" | "imperial"
}

export const UnitConversionContext = createContext(
    {} as IUnitConversion
)

export const useUnits = () => useContext(UnitConversionContext)

function UnitConversionProvider({ children }: { children: ReactNode }) {
    const { user } = useAuth()
    const unitName = user?.unit_type === true ? "metric" : "imperial"

    const normalizeUnit = (unitName: string) => {
        return unitName.replace(/[°%]/, "")
    }

    const convertUnits = (
        value: number,
        variable: IUnitsConfig,
        direction: "convertUnits" | "resetUnits" = "convertUnits"
    ) => {
        if (!user || !variable) return NaN
        // user.unit_type
        // TRUE = METRIC FALSE = IMPERIAL
        try {
            const user_units = normalizeUnit(
                user.unit_type ? variable.units_metric : variable.units_imperial
            )
            const source_units = normalizeUnit(variable.units_metric)
            const from_units =
                direction === "convertUnits" ? source_units : user_units
            const to_units =
                direction === "convertUnits" ? user_units : source_units
            const result = convert(value)
                .from(from_units as AllMeasuresUnits)
                .to(to_units as AllMeasuresUnits)
            return result as number
        } catch (err) {
            return value as number
        }
    }

    const getUnit = (variable: IHazardVariable) => {
        if (!user || !variable?.units_metric || !variable?.units_imperial)
            return "N/A"
        return user.unit_type ? variable.units_metric : variable.units_imperial
    }

    return (
        <UnitConversionContext.Provider
            value={{ convertUnits, getUnit, unitName }}>
            {children}
        </UnitConversionContext.Provider>
    )
}

export default UnitConversionProvider
