import mapboxgl from "../mapboxgl"
import { useCallback, useEffect, useRef, useState } from "react"
import { IPolygonGroup } from "./types"
import "../mapbox.css"
import CustomMapControls from "../CustomMapControls"
import ShadedRegionsMapLegend from "./ShadedRegionsMapLegend"
import bbox from "geojson-bbox"
import { appendPolygonGroup, removePolygonGroup, setupMap } from "./utils"

const DEFAULT_MAPBOX_CONFIG = {
    style: "mapbox://styles/geramv/cl5k2k0ms001414ni9wxhbpsd",
    center: [0, 0] as [number, number],
    zoom: 1,
    attributionControl: false,
    preserveDrawingBuffer: true,
}

const ShadedRegionsMap = ({
    data,
    title,
    loading,
}: {
    data: Record<string, IPolygonGroup>
    title?: string
    loading?: boolean
}) => {
    const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map>()
    const [layers, setLayers] = useState<(string | undefined)[]>([])

    const prevContainerRect = useRef<DOMRect | undefined>()
    const containerRef = useRef<HTMLElement | undefined>()

    const mapRef = useCallback((container: HTMLDivElement | null) => {
        if (!container) return
        containerRef.current = container

        const mapboxConfig = { ...DEFAULT_MAPBOX_CONFIG, container }

        const mapboxMap = setupMap({
            containerRef,
            mapboxConfig,
            prevContainerRect,
        })
        setMapboxMap(mapboxMap)
    }, [])

    useEffect(() => {
        if (!mapboxMap) return
        if (loading) {
            // Clear all layers if there are any
            if (layers.length > 0) {
                layers.forEach((polyGroupId) => {
                    if (!polyGroupId) return
                    removePolygonGroup(mapboxMap, polyGroupId)
                })
            }
            mapboxMap.flyTo({
                center: DEFAULT_MAPBOX_CONFIG.center,
                zoom: DEFAULT_MAPBOX_CONFIG.zoom,
            })
        } else {
            // Paint each region onto the map
            const bounds = new mapboxgl.LngLatBounds()
            const layerGroups = Object.entries(data).map(([id, polyGroup]) => {
                const geojsonBounds = bbox(polyGroup.geojson)
                if (Math.abs(geojsonBounds[0]) === Infinity) return
                const appended = appendPolygonGroup(mapboxMap, id, polyGroup)
                bounds.extend(geojsonBounds)
                mapboxMap.fitBounds(bounds, {
                    padding: 20,
                })
                if (appended) return id
            })
            setLayers(layerGroups.flat())
        }
    }, [data, mapboxMap, loading])

    return (
        <div className="relative w-full h-full">
            <div
                ref={mapRef}
                className="w-full h-full min-w-[320px] min-h-[320px] rounded-sm">
                {mapboxMap && <CustomMapControls map={mapboxMap} />}
                <ShadedRegionsMapLegend
                    title={title}
                    data={data}
                />
            </div>
        </div>
    )
}
export default ShadedRegionsMap
