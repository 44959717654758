import {
    LegacyRef,
    MouseEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { ArrowBottom, CancelIcon } from "../../../../climateui/icons"
import ChevronIcon from "../../../../climateui/icons/ChevronArrow"
import { DateTime } from "luxon"
import { Button, DatePicker, Tooltip } from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"
import { useSearchParams } from "react-router-dom"
import { useFlagValue } from "../../../../hooks"

/* CONSTS > START */
export const ALL = -2
export const CUSTOM = -1
export const WEEKLY = 0
export const MONTHLY = 1
const ALL_DEFAULT_MONTH_OFFSET = 7
/* CONSTS < END */
const DateNavigator = ({
    onChange,
    initialDate,
    defaultMode = MONTHLY,
    maxDate,
    maxDateTooltip,
    minDate,
    minDateTooltip,
    isLoading,
}: {
    onChange?: (date: DateTime, endDate: DateTime) => void
    initialDate?: DateTime
    defaultMode?: number
    maxDate?: DateTime
    maxDateTooltip?: string
    minDate?: DateTime
    minDateTooltip?: string
    isLoading?: boolean
}) => {
    /* CONSTS > START */
    const START_DATE = DateTime.now().startOf("day")
    const END_DATE = START_DATE.endOf("year")
    /* CONSTS < END */

    /* HOOKS > START */
    const { t } = useTranslate()
    const fsAllDefaultMonthOffset = useFlagValue(
        "feature_alerts_all_default_month_offset"
    ) as number | undefined
    /* HOOKS < END */

    /* STATE > START */
    const [showPicker, setShowPicker] = useState(false)
    const [queryParams, setQueryParams] = useSearchParams()
    const queryStart = queryParams.get("start")
    const queryStartDate = useRef(
        queryStart ? DateTime.fromISO(queryStart) : undefined
    )
    const queryEnd = queryParams.get("end")
    const queryEndDate = useRef(
        queryEnd ? DateTime.fromISO(queryEnd) : undefined
    )
    const isValidQueryDate = !!queryStartDate.current && !!queryEndDate.current
    const outsideRef = useOutsideComponentClickHandler(() =>
        setShowPicker(false)
    )
    const pickerRef = useRef<HTMLElement>()
    const togglePicker: MouseEventHandler<HTMLDivElement> = (ev) => {
        // Exit if the clicked element is or belongs to the calendar
        if (
            ev.target === pickerRef.current ||
            (pickerRef.current && pickerRef.current.contains(ev.target as Node))
        )
            return
        setShowPicker(!showPicker)
    }
    const [mode, _setMode] = useState(isValidQueryDate ? CUSTOM : defaultMode)
    const [lastMode, setLastMode] = useState(
        mode === CUSTOM ? defaultMode : mode
    )

    const [startDate, setStartDate] = useState<DateTime>(
        queryStartDate.current ?? START_DATE
    )
    const [endDate, setEndDate] = useState<DateTime>(
        queryEndDate.current ?? END_DATE
    )
    const rangeStr = useMemo(() => {
        switch (mode) {
            case MONTHLY:
                return startDate.toFormat("MMMM yyyy")
            default:
                return `${startDate.toLocaleString(
                    DateTime.DATE_MED
                )} - ${endDate.toLocaleString(DateTime.DATE_MED)}`
        }
    }, [mode, startDate, endDate])

    /* STATE < END */

    /* FUNCS > START */
    const getDefaults = (_mode: number) => {
        if (!initialDate) return [START_DATE, END_DATE]
        const today = DateTime.now().startOf("day")
        switch (_mode) {
            case ALL:
                return [
                    minDate || today,
                    maxDate ||
                        today.plus({
                            months:
                                fsAllDefaultMonthOffset ||
                                ALL_DEFAULT_MONTH_OFFSET,
                        }),
                ]
            case WEEKLY:
                return [initialDate.startOf("week"), initialDate.endOf("week")]
            case MONTHLY:
            default:
                return [
                    initialDate.startOf("month"),
                    initialDate.endOf("month"),
                ]
        }
    }
    const restoreDefault = (_mode: number) => {
        const [defaultStart, defaultEnd] = getDefaults(_mode)
        setStartDate(defaultStart)
        setEndDate(defaultEnd)
    }
    const setMode = (_mode: number) => {
        // Keep track of the last mode different to CUSTOM
        if (mode !== CUSTOM) {
            setLastMode(mode)
        }
        if (_mode !== CUSTOM) restoreDefault(_mode)
        _setMode(_mode)
    }
    const handleDateChange = (_startDate?: DateTime, _endDate?: DateTime) => {
        // If only one is defined, exit
        if (!!_startDate !== !!_endDate) return
        // If the dates were cleared, set the default
        if (!_startDate && !_endDate)
            restoreDefault(mode === CUSTOM ? lastMode : mode)
        // If the range is defined, set the
        if (_startDate && _endDate) {
            setStartDate(_startDate)
            setEndDate(_endDate)
            setMode(CUSTOM)
        }
        setShowPicker(false)
    }
    const GO_BACK_OFFSET = -1
    const GO_FORWARD_OFFSET = 1
    const getOffset = (timeUnit: number) => {
        switch (mode) {
            case WEEKLY:
                return { weeks: timeUnit }
            case MONTHLY:
            default:
                return { months: timeUnit }
        }
    }
    const offsetDate = (timeUnit: number) => {
        const offset = getOffset(timeUnit)
        setStartDate(startDate.plus(offset))
        setEndDate(endDate.plus(offset))
    }
    /* FUNCS < END */

    /* FUNC DEPENDENT STATE > START */
    const canGoBack = useMemo(() => {
        if (!minDate) return true
        const offset = getOffset(GO_BACK_OFFSET)
        // TODO: If we're supporting hourly navigation, we need to change the
        // `startOf` param
        const offsetUTCEndDate = endDate.plus(offset).toUTC().startOf("day")
        const offsetUTCMinDate = minDate.toUTC()
        if (offsetUTCEndDate < offsetUTCMinDate) return false
        return true
    }, [endDate, minDate])
    const canGoForward = useMemo(() => {
        if (!maxDate) return true
        const offset = getOffset(GO_FORWARD_OFFSET)
        // TODO: If we're supporting hourly navigation, we need to change the
        // `startOf` param
        const offsetUTCStartDate = startDate.plus(offset).toUTC().startOf("day")
        const offsetUTCMaxDate = maxDate.toUTC()
        if (offsetUTCStartDate > offsetUTCMaxDate) return false
        return true
    }, [endDate, maxDate])
    /* FUNC DEPENDENT STATE < END */

    /* LIFECYCLE HOOKS > START */
    useEffect(() => {
        if (!isValidQueryDate) restoreDefault(mode === CUSTOM ? lastMode : mode)
    }, [initialDate])
    useEffect(() => {
        if (!isValidQueryDate) setMode(defaultMode)
    }, [defaultMode])

    useEffect(() => {
        if (onChange) {
            onChange(startDate, endDate)
        }
        queryParams.set("start", startDate.toISODate())
        queryParams.set("end", endDate.toISODate())
        setQueryParams(queryParams)
    }, [startDate, endDate])
    /* LIFECYCLE HOOKS < END */

    /* STYLES > START */
    const arrowClasses = ["w-6 h-6"]
    const optBttnsClasses = [
        "bg-white border-gray-10 text-gray-60",
        "hover:border-accent-medium hover:text-accent",
    ]
    const selectedBttnClasses = [
        "text-accent border-accent-medium fill-accent bg-accent-light",
    ]
    /* STYLES < END */

    /* LEGIBILITY FUNCS > START */
    /* These functions serve the sole purpose of making the JSX more legible */
    const goBack = () => {
        if (!canGoBack) return
        offsetDate(GO_BACK_OFFSET)
    }
    const goForward = () => {
        if (!canGoForward) return
        offsetDate(GO_FORWARD_OFFSET)
    }
    const clearCustom = () => {
        setMode(lastMode)
    }
    const getModeSelectedClasses = (_mode: number) => {
        return mode === _mode
            ? selectedBttnClasses.join(" ")
            : optBttnsClasses.join(" ")
    }
    const getArrowAvailability = (enabled: boolean) => {
        return enabled
            ? "fill-gray-60 cursor-pointer"
            : "fill-gray-30 cursor-not-allowed"
    }
    /* LEGIBILITY FUNCS < END */

    return (
        <div className="relative flex flex-row flex-wrap items-center justify-center w-full px-2 py-1 sm:flex-nowrap sm:justify-between bg-gray-3 gap-1">
            {/* DATE PICKER */}
            <div className="flex flex-row items-center justify-between">
                {/* BACK ARROW */}
                {mode >= WEEKLY && (
                    <Tooltip
                        doShow={!!minDate && !!minDateTooltip && !canGoBack}
                        content={minDateTooltip || ""}>
                        <div
                            onClick={goBack}
                            className={[
                                ...arrowClasses,
                                getArrowAvailability(canGoBack),
                                "rotate-90",
                            ].join(" ")}>
                            <ChevronIcon />
                        </div>
                    </Tooltip>
                )}
                {/* CURRENT RANGE */}
                <div
                    ref={outsideRef}
                    role="button"
                    className="flex flex-row items-center cursor-pointer"
                    onClick={togglePicker}>
                    <Tooltip
                        content={rangeStr}
                        customStyle={[
                            "relative",
                            "flex",
                            "items-center",
                            "justify-start",
                            "group",
                            "transition-all",
                            "duration-200",
                        ].join(" ")}
                        doShow={isLoading !== undefined ? !isLoading : true}>
                        <div className="pl-2 font-medium truncate max-w-[24ch] sm:max-w-[32ch] lg:max-w-[16ch] xl:max-w-[32ch] text-gray-90">
                            {isLoading ? t("loading", "Loading...") : rangeStr}
                        </div>
                    </Tooltip>
                    <div
                        className={[...arrowClasses, "fill-gray-60"].join(" ")}>
                        <ArrowBottom />
                    </div>
                    {showPicker && (
                        <div
                            ref={pickerRef as LegacyRef<HTMLDivElement>}
                            className="absolute z-20">
                            <DatePicker
                                range={true}
                                initialDate={startDate}
                                initialEndDate={endDate}
                                onChange={handleDateChange}
                            />
                        </div>
                    )}
                </div>
                {/* FORWARD ARROW */}
                {mode >= WEEKLY && (
                    <Tooltip
                        doShow={!!maxDate && !!maxDateTooltip && !canGoForward}
                        content={maxDateTooltip || ""}>
                        <div
                            onClick={goForward}
                            className={[
                                ...arrowClasses,
                                getArrowAvailability(canGoForward),
                                "-rotate-90",
                            ].join(" ")}>
                            <ChevronIcon />
                        </div>
                    </Tooltip>
                )}
                {/* CLEAR CUSTOM X */}
                {mode < WEEKLY && (
                    <div
                        className={[
                            ...arrowClasses,
                            "cursor-pointer hover:fill-accent-dark fill-accent",
                        ].join(" ")}
                        onClick={clearCustom}>
                        <CancelIcon />
                    </div>
                )}
            </div>
            {/* WEEK/MONTH SELECT */}
            <div className="flex flex-row">
                <Button
                    onClick={() => setMode(WEEKLY)}
                    customClasses={[
                        getModeSelectedClasses(WEEKLY),
                        "rounded-tr-none rounded-br-none",
                    ].join(" ")}
                    type="secondary"
                    label={t("week", "Week")}></Button>
                <Button
                    onClick={() => setMode(MONTHLY)}
                    customClasses={[
                        getModeSelectedClasses(MONTHLY),
                        "rounded-t-none rounded-b-none",
                    ].join(" ")}
                    type="secondary"
                    label={t("month", "Month")}></Button>
                <Button
                    onClick={() => setMode(ALL)}
                    customClasses={[
                        getModeSelectedClasses(ALL),
                        "rounded-tl-none rounded-bl-none",
                    ].join(" ")}
                    type="secondary"
                    label={t("all", "All")}></Button>
            </div>
        </div>
    )
}

export default DateNavigator
