import { useContext } from "react"
import {
    IAlert,
    IFullTrigger,
    IHazardProfile,
    IHazardVariable,
    IRiskProfile,
} from "../../../../../types"
import {
    CalendarTimelineItem,
    ICalendarTimelineRow,
    IAlertRequest,
} from "../../../../../climateui/types/widget"
import GenericCalendarWidget from "../../../../../climateui/components/Widgets/Table/GenericCalendarWidget"
import { ITableWidgetProps } from "../../../../../climateui/components/Widgets/Table/TableWidget"
import dashboardVariables from "../../dashboardVariables"
import StackedVariablesIcon from "../../../../../climateui/icons/variableIcons/StackedVariablesIcon"
import { DateTime } from "luxon"
import { createGraphQLProxy } from "../../../../../utils/networking"
import { DefaultParamType, TFnType, useTranslate } from "@tolgee/react"
import RiskProfileDetailCard from "../../../Alerts/components/RiskProfileDetailCard"
import {
    formatConditionDescription,
    formatDateRange,
    formatHazardDescription,
    formatOccurrence,
    formatPercentile,
    formatter,
    getMagnitudeDescription,
    getMagnitudeLabel,
    getProbabilityLabel,
    TimeResolutionValues,
} from "../../../Alerts/utils"
import { IAlertDetailCardData } from "../../../Alerts/components/SidePanel"
import { CalendarIcon } from "../../../../../climateui/icons"
import HistoryIcon from "../../../../../climateui/icons/HistoryIcon"
import PercentageIcon from "../../../../../climateui/icons/PercentageIcon"
import MagnitudeIcon from "../../../../../climateui/icons/MagnitudeIcon"
import { DefaultRiskIcon } from "../../../../../climateui/icons/riskIcons"
import { useAssets } from "../../../../../providers"
import { alertCategoriesEnum } from "../../../Alerts/components/mapUtils"
import {
    UnitConversionContext,
    IUnitsConfig,
    useUnits,
} from "../../../../../providers/UnitConversionProvider"

export const getAlertCardDetailsInfo = (
    translateFn: TFnType<DefaultParamType>,
    alert: IAlertRequest,
    alertRiskProfile: IRiskProfile,
    getUnit: (variable: IHazardVariable) => string,
    convertUnits: (
        value: number,
        variable: IUnitsConfig,
        direction: "convertUnits" | "resetUnits"
    ) => number
) => {
    const numFormatter = Intl.NumberFormat(undefined, {
        maximumFractionDigits: 1,
    })
    const itemHazardProfiles = createGraphQLProxy(
        (alert.processing_run as IFullTrigger).risk_profile
    ).hazard_profiles
    const orderedHazardProfiles: IHazardProfile[] = []
    const startProfile = itemHazardProfiles.find(
        (profile: IHazardProfile) =>
            profile.logical_op?.toUpperCase() === "START"
    )
    const andProfiles = itemHazardProfiles.filter(
        (profile: IHazardProfile) => profile.logical_op?.toUpperCase() === "AND"
    )

    if (startProfile) {
        orderedHazardProfiles.push(startProfile)
    }

    orderedHazardProfiles.push(...andProfiles)
    const probabilityPercentage = formatter.format(alert.avg_risk_value)
    const probabilityLabel = getProbabilityLabel(alert.avg_risk_value * 100)

    const magnitudeLabel = alert.expected_percentile
        ? getMagnitudeLabel(alert.expected_percentile)
        : translateFn("unknownPercentile", "Unknown percentile")

    const expectedMagnitudeValues =
        alert.expected_magnitude_list.map((mag, index) =>
            convertUnits(
                mag,
                orderedHazardProfiles[index]?.hazard_variable,
                "convertUnits"
            )
        ) || []
    const magnitudeDescription = expectedMagnitudeValues
        ? getMagnitudeDescription(
              expectedMagnitudeValues,
              orderedHazardProfiles,
              translateFn,
              getUnit
          )
        : ""

    const hazardProfilesDescriptionsArray: string[] = orderedHazardProfiles.map(
        (profile: any) => {
            const description = formatConditionDescription(
                translateFn,
                alertRiskProfile?.type,
                profile.hazard_variable.readable_name,
                profile.conditional,
                profile.window,
                numFormatter.format(profile.threshold),
                profile.hazard_variable.units
            )
            return description
        }
    )

    const informationInAlertCard: Record<string, IAlertDetailCardData> = {
        dateRange: {
            icon: <CalendarIcon fill="#666D74" />,
            textLines: [
                formatDateRange(
                    (["daily", "weekly", "monthly"].includes(
                        alert?.time_resolution
                    )
                        ? alert.time_resolution
                        : "daily") as TimeResolutionValues,
                    alert.start_date,
                    alert.end_date
                ),
            ],
        },
        magnitude: {
            icon: <MagnitudeIcon fill="#666D74" />,
            textLines: [
                `${magnitudeLabel}: ${magnitudeDescription}`,
                `${formatPercentile(
                    alert.expected_percentile ?? 0
                )} ${translateFn(
                    "historicalPercentile",
                    "Historical Percentile"
                )}.`,
            ],
        },
        probability: {
            icon: <PercentageIcon fill="#666D74" />,
            textLines: [
                `${probabilityLabel}: ${probabilityPercentage} ${translateFn(
                    "probability",
                    "probability"
                )}`,
            ],
        },
        occurrence: {
            icon: <HistoryIcon fill="#666D74" />,
            textLines: formatOccurrence(
                translateFn,
                alert.frequency || 0,
                alert.last_occurrence || ""
            ),
        },
        hazardProfileDescription: {
            icon: <DefaultRiskIcon fill="#666D74" />,
            textLines: [
                formatHazardDescription(hazardProfilesDescriptionsArray),
                alertRiskProfile.type === "custom"
                    ? translateFn(
                          "basedOnThreshold",
                          "Based on threshold specified by your account Admin"
                      )
                    : "",
            ],
        },
    }
    return informationInAlertCard
}

function CalendarAlertHover(props: {
    readonly alert: CalendarTimelineItem
    readonly t: TFnType<DefaultParamType>
}) {
    const { alert, t } = props
    const { varieties } = useAssets()
    const availableVarietiesInAlert: string[] = []
    varieties &&
        alert.alertInfo.processing_run.risk_profile.varieties.results.forEach(
            (variety: any) => {
                const assetName = varieties?.[variety.id]?.asset.name
                const varietyName = varieties?.[variety.id]?.name
                if (assetName && varietyName)
                    availableVarietiesInAlert.push(
                        `${assetName} (${varietyName})`
                    )
            }
        )
    return (
        <div className="w-[310px] m-2">
            <RiskProfileDetailCard
                t={t}
                enableForecastButton={false}
                riskProfileInfo={alert.alertHoverInfo}
                riskProfilesVarieties={availableVarietiesInAlert || []}
                alertCategory={
                    alert.alertInfo.category.toLowerCase() as alertCategoriesEnum
                }
            />
        </div>
    )
}

function SeasonalCalendarWidget(props: ITableWidgetProps) {
    const {
        data,
        loading,
        error,
        empty,
        reload,
        title,
        isPaginated,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
    } = props

    const { t } = useTranslate()

    const { getUnit } = useContext(UnitConversionContext)
    const { convertUnits } = useUnits()
    const urlParams = new URLSearchParams(location.search)
    const granularity = urlParams.get("scope") ?? "daily"

    const dashboardLocations: Record<string, string> = {}
    const alertsPerLocation: Record<string, CalendarTimelineItem[]> = {}

    if (data) {
        data.forEach((alert) => {
            const alertLocation = alert.processing_run.location
            const hazardProfile =
                alert.processing_run.risk_profile.hazard_profiles.results || {}
            const startDate = DateTime.fromISO(alert.start_date)
            const endDate = DateTime.fromISO(alert.end_date)

            const alertItem: CalendarTimelineItem = {
                text: alert.processing_run.risk_profile.name,
                startDate: startDate,
                endDate: endDate,
                duration: alert.duration,
                color:
                    hazardProfile.length > 1
                        ? "red"
                        : dashboardVariables[
                              hazardProfile[0]?.hazard_variable
                                  .dashboard_variable
                          ].chartConfig.color,
                icon:
                    hazardProfile.length > 1
                        ? StackedVariablesIcon
                        : dashboardVariables[
                              hazardProfile[0]?.hazard_variable
                                  .dashboard_variable
                          ].icon,
                alertInfo: alert as IAlertRequest,
                alertHoverInfo: {
                    alert: alert as IAlert,
                    riskProfile: alert.processing_run.risk_profile,
                    location: alertLocation,
                    alertDetailInfo: getAlertCardDetailsInfo(
                        t,
                        alert as IAlertRequest,
                        alert.processing_run.risk_profile,
                        getUnit,
                        convertUnits
                    ),
                },
            }

            if (!dashboardLocations[alertLocation.id]) {
                dashboardLocations[alertLocation.id] = alertLocation.name
            }
            if (!alertsPerLocation[alertLocation.id]) {
                alertsPerLocation[alertLocation.id] = [alertItem]
            } else {
                alertsPerLocation[alertLocation.id].push(alertItem)
            }
        })
    }

    const alertsCalendarData = Object.keys(dashboardLocations).map(
        (location) => {
            alertsPerLocation[location].sort((a, b) =>
                a.startDate > b.startDate ? 1 : -1
            )
            return {
                text: {
                    value: dashboardLocations[location],
                    link: `/seasonal/dashboards/location?locId=${location}`,
                },
                items: alertsPerLocation[location],
            }
        }
    )
    return (
        <GenericCalendarWidget
            widgetProps={{
                title: title,
                data: alertsCalendarData,
                columns: [],
                loading: loading,
                empty: empty,
                error: error,
                reload: reload,
                isPaginated: isPaginated,
                errorMessage: errorMessage,
                reloadMessage: reloadMessage,
                noResultsMessage: noResultsMessage,
                tryAnotherFilterMessage: tryAnotherFilterMessage,
            }}
            granularity={granularity}
            alertsCalendarData={alertsCalendarData as ICalendarTimelineRow[]}
            toolTip={(alert: CalendarTimelineItem) => (
                <CalendarAlertHover
                    alert={alert}
                    t={t}
                />
            )}
            translateFn={t}
        />
    )
}

export default SeasonalCalendarWidget
